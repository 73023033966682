import React from "react"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import GridContent from "./GridContent"

const RemainingAmount = ({ t, remainingAmount, currency }) => {
  return (
    <GridContent
      title={t("remainingAmount")}
      data={
        (remainingAmount
          ? addThousandSeparator(remainingAmount.toString())
          : "0") +
        " " +
        currency
      }
    />
  )
}

export default RemainingAmount
