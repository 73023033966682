import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../style/ProjectColors"

const StyledInput = ({ children, maxWidth = "100px", customWidth }) => {
  return (
    <InputWrapper maxWidth={maxWidth} customWidth={customWidth}>
      {children}
    </InputWrapper>
  )
}
export default StyledInput

const InputWrapper = styled.div`
  input {
    padding: 3px 0px 3px 5px;
    width: ${(props) => props.customWidth};
    max-width: ${(props) => props.maxWidth};
    border-radius: 5px;
    margin-left: 0;
    border: solid 1px ${ProjectColors.InputBorder};
  }
`
