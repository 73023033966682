import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { SecondaryButton } from "@flow/buttons"
import { Icons } from "@flow/icons"

import downloadLink from "../../util/downloadLink"
import axios from "axios"

const DownloadButtonStyled = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize};

  svg {
    padding-right: 1em;
  }
`

const DownloadAttachmentButton = ({
  flowId,
  attachmentKey,
  filename,
  title,
  fontSize = "small",
}) => {
  const onDownloadClick = async (e) => {
    e.preventDefault()
    const { data } = await axios.get(
      `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`,
      {
        responseType: "arraybuffer",
      }
    )
    return downloadLink(data, filename)
  }

  return (
    <DownloadButtonStyled
      size="large"
      fontSize={fontSize}
      onClick={async (e) => await onDownloadClick(e)}
    >
      <Icons.FilePdf />
      {title}
    </DownloadButtonStyled>
  )
}

DownloadAttachmentButton.propTypes = {
  flowId: PropTypes.string.isRequired,
  attachmentKey: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  title: PropTypes.string,
}

DownloadAttachmentButton.defaultProps = {
  title: "Download file",
}

export default DownloadAttachmentButton
