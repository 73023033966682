import React from "react"
import { RadioButton } from "@flow/forms"
import { Colors, FontStyles } from "@flow/style"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"
import checkList from "../../schemas/checkListSchema"

const CheckList = ({ stateData, setStateData, t, readonly }) => {
  const handleChange = (key, value) => {
    setStateData((prevState) => {
      const newData = { ...prevState }

      if (newData[key] !== value) {
        newData[key] = value
      } else {
        // Should potentially delete instead? Validation handles this well anyway.
        newData[key] = null
      }

      return newData
    })
  }

  return (
    <Content>
      <CustomTable>
        <tbody>
          <TR>
            <Heading>{t("checklist-payout")}</Heading>
            <TH>{t("controlled")}</TH>
            <TH>{t("non-relevant")}</TH>
          </TR>
          {(stateData.payoutType === "partialPayout" ||
            stateData.payoutType === "advancePayout" ||
            stateData.payoutType === "finalPayout") && (
            <>
              <TR>
                <TH>{t("special-conditions")}</TH>
                {checkList.specialConditions.map((row, i) => (
                  <TH key={i}>
                    <RadioButtonWrapper>
                      <RadioButton
                        disabled={readonly}
                        name="specialConditions"
                        id={`specialConditions${i}`}
                        checked={stateData.specialConditions === row.value}
                        onChange={() => {}}
                        onClick={() =>
                          handleChange("specialConditions", row.value)
                        }
                      />
                    </RadioButtonWrapper>
                  </TH>
                ))}
              </TR>
              <TR>
                <TH>{t("project-account-signed")}</TH>
                {checkList.projectAccounting.map((row, i) => (
                  <TH key={`b${i}`}>
                    <RadioButtonWrapper>
                      <RadioButton
                        disabled={readonly}
                        name="projectAccounting"
                        checked={stateData.projectAccounting === row.value}
                        onChange={() => {}}
                        onClick={() =>
                          handleChange("projectAccounting", row.value)
                        }
                      />
                    </RadioButtonWrapper>
                  </TH>
                ))}
              </TR>
              <TR>
                <TH>{t("nav-registered")}</TH>
                {checkList.navRegistered.map((row, i) => (
                  <TH key={`c${i}`}>
                    <RadioButtonWrapper>
                      <RadioButton
                        disabled={readonly}
                        name="navRegistered"
                        checked={stateData.navRegistered === row.value}
                        onChange={() => {}}
                        onClick={() => handleChange("navRegistered", row.value)}
                      />
                    </RadioButtonWrapper>
                  </TH>
                ))}
              </TR>
              <TR>
                <TH>{t("payoutPowerOfAttorney")}</TH>
                {checkList.payoutPowerOfAttorney.map((row, i) => (
                  <TH key={`c${i}`}>
                    <RadioButtonWrapper>
                      <RadioButton
                        disabled={readonly}
                        name="payoutPowerOfAttorney"
                        checked={stateData.payoutPowerOfAttorney === row.value}
                        onChange={() => {}}
                        onClick={() =>
                          handleChange("payoutPowerOfAttorney", row.value)
                        }
                      />
                    </RadioButtonWrapper>
                  </TH>
                ))}
              </TR>
              <TR>
                <TH>{t("ongoingLoanManagement")}</TH>
                {checkList.ongoingLoanManagement.map((row, i) => (
                  <TH key={`c${i}`}>
                    <RadioButtonWrapper>
                      <RadioButton
                        disabled={readonly}
                        name="ongoingLoanManagement"
                        checked={stateData.ongoingLoanManagement === row.value}
                        onChange={() => {}}
                        onClick={() =>
                          handleChange("ongoingLoanManagement", row.value)
                        }
                      />
                    </RadioButtonWrapper>
                  </TH>
                ))}
              </TR>
              <TR>
                <TH>{t("anticipatedDefaultAssessment")}</TH>
                {checkList.anticipatedDefaultAssessment.map((row, i) => (
                  <TH key={`c${i}`}>
                    <RadioButtonWrapper>
                      <RadioButton
                        disabled={readonly}
                        name="anticipatedDefaultAssessment"
                        checked={
                          stateData.anticipatedDefaultAssessment === row.value
                        }
                        onChange={() => {}}
                        onClick={() =>
                          handleChange(
                            "anticipatedDefaultAssessment",
                            row.value
                          )
                        }
                      />
                    </RadioButtonWrapper>
                  </TH>
                ))}
              </TR>
              <TR>
                <TH>{t("innovationContractCollaborationAgreement")}</TH>
                {checkList.innovationContractCollaborationAgreement.map(
                  (row, i) => (
                    <TH key={`c${i}`}>
                      <RadioButtonWrapper>
                        <RadioButton
                          disabled={readonly}
                          name="innovationContractCollaborationAgreement"
                          checked={
                            stateData.innovationContractCollaborationAgreement ===
                            row.value
                          }
                          onChange={() => {}}
                          onClick={() =>
                            handleChange(
                              "innovationContractCollaborationAgreement",
                              row.value
                            )
                          }
                        />
                      </RadioButtonWrapper>
                    </TH>
                  )
                )}
              </TR>
            </>
          )}

          {stateData.payoutType === "partialPayout" && (
            <TR>
              <TH>{t("progress-report")}</TH>
              {checkList.projectAccounting.map((row, i) => (
                <TH key={i}>
                  <RadioButtonWrapper>
                    <RadioButton
                      disabled={readonly}
                      name="progressReport"
                      checked={stateData.progressReport === row.value}
                      onChange={() => {}}
                      onClick={() => handleChange("progressReport", row.value)}
                    />
                  </RadioButtonWrapper>
                </TH>
              ))}
            </TR>
          )}

          {stateData.payoutType === "finalPayout" && (
            <>
              <TR>
                <TH>{t("certificateOfCompletion")}</TH>
                {checkList.projectAccounting.map((row, i) => (
                  <TH key={`a${i}`}>
                    <RadioButtonWrapper>
                      <RadioButton
                        disabled={readonly}
                        name="certificateOfCompletion"
                        checked={
                          stateData.certificateOfCompletion === row.value
                        }
                        onChange={() => {}}
                        onClick={() =>
                          handleChange("certificateOfCompletion", row.value)
                        }
                      />
                    </RadioButtonWrapper>
                  </TH>
                ))}
              </TR>
              <TR>
                <TH>{t("finalReport")}</TH>
                {checkList.projectAccounting.map((row, i) => (
                  <TH key={`b${i}`}>
                    <RadioButtonWrapper>
                      <RadioButton
                        disabled={readonly}
                        name="finalReport"
                        checked={stateData.finalReport === row.value}
                        onChange={() => {}}
                        onClick={() => handleChange("finalReport", row.value)}
                      />
                    </RadioButtonWrapper>
                  </TH>
                ))}
              </TR>
            </>
          )}

          {stateData.mustCheckSigners && (
            <TR>
              <TH>{t("check-signers")}</TH>
              {checkList.checkSigners.map((row, i) => (
                <TH key={i}>
                  <RadioButtonWrapper>
                    <RadioButton
                      disabled={readonly}
                      name="checkSigners"
                      checked={stateData.checkSigners === row.value}
                      onChange={() => {}}
                      onClick={() => handleChange("checkSigners", row.value)}
                    />
                  </RadioButtonWrapper>
                </TH>
              ))}
            </TR>
          )}
        </tbody>
      </CustomTable>
    </Content>
  )
}
export default CheckList
const Content = styled.div`
  padding-top: 1em;
`

const ErrorField = styled.span`
  color: ${Colors.Ferrari};
`

const RadioButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const CustomTable = styled.table`
  width: 100%;
  font-size: small;
  border-collapse: collapse;
`

const TR = styled.tr`
  &:nth-child(even) {
    background-color: ${ProjectColors.Slate90};
  }
`
const TH = styled.th`
  font-weight: normal;
  padding: 12px;

  &:nth-child(1) {
    text-align: left;
  }
`

const Heading = styled.th`
  padding: 0;
  margin: 0;
  text-align: left;
  font-weight: normal;
  font-size: ${FontStyles.Normal};
`
