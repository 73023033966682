import React, { useState, useRef, useCallback, useEffect } from "react"
import { RadioButton } from "@flow/forms"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import { withTranslation } from "react-i18next"
import Signer from "../components/selectSigners/Signer"
import SelectedPerson from "../components/selectSigners/SelectedPerson"
import Layout, { Context } from "../components/common/Layout"
import AddSigner from "../components/selectSigners/AddSigner"
import ReactForm from "../components/common/ReactForm"
import { useForm } from "react-hook-form"
import Tabs from "../components/common/Tabs"
import { returnFirstArgWithValue } from "../util/returnValue"
import Error from "../components/common/Error"
import Notice from "../components/common/Notice"

const ActiveSignRule = ({ rule, t, updateSelectedRule, isForeignAccount }) => {
  if (rule == null) return <SignersInformationList></SignersInformationList>

  const isOther = rule.kode === "OTHER"
  return (
    <SignersInformationList>
      <LabelSpan>{t("signers")}</LabelSpan>
      {rule?.personRolleKombinasjon?.map((person, index) => (
        <SelectedPerson
          key={`${rule.kode}_${rule.index}_${person.fodselsnummer}_${index}`}
          signRule={rule}
          index={index}
          updateSelectedRule={updateSelectedRule}
          person={person}
          isOther={isOther}
          t={t}
        />
      ))}
      {isOther && (
        <AddSigner
          signRule={rule}
          updateSelectedRule={updateSelectedRule}
          t={t}
          isForeignAccount={isForeignAccount}
        />
      )}
    </SignersInformationList>
  )
}

const SignRuleOption = ({
  rule,
  selectedRuleIndex,
  setSelectedRuleIndex,
  t,
}) => {
  const isChecked = selectedRuleIndex === rule.index
  return (
    <>
      <RadioButtonWrapper>
        <RadioButton
          name="signRule"
          id={`signRule${rule.index}`}
          label={t(rule.tekstforklaring)}
          value={isChecked}
          defaultChecked={isChecked}
          onChange={() => {
            setSelectedRuleIndex(rule.index)
          }}
        />
      </RadioButtonWrapper>
      <SignerContentDiv>
        {rule.personRolleKombinasjon.map((person, index) => (
          <Signer
            key={`${rule.index}-${index}`}
            signer={person}
            isBold={isChecked}
          />
        ))}
      </SignerContentDiv>
    </>
  )
}

const SignRuleOptions = ({
  rules,
  header,
  selectedRuleIndex,
  setSelectedRuleIndex,
  t,
}) => {
  if (!rules || rules.length === 0) return <></>

  return (
    <>
      <LabelSpan>{header}</LabelSpan>
      {rules.map((rule, index) => (
        <SignRuleOption
          t={t}
          key={`${header}-${rule.index}`}
          rule={rule}
          selectedRuleIndex={selectedRuleIndex}
          setSelectedRuleIndex={setSelectedRuleIndex}
        />
      ))}
    </>
  )
}

const SignRuleSelector = ({
  initialRule,
  orgNr,
  t,
  rules,
  updateRules,
  isForeignAccount,
}) => {
  const [selectedRuleIndex, setSelectedRuleIndex] = useState(initialRule?.index)

  const selectedRule = rules[selectedRuleIndex]
  const standardRules = rules.filter((rule) => rule.type === "signatur")
  const prokuraRules = rules.filter((rule) => rule.type === "prokura")
  const otherRules = rules.filter((rule) => rule.type === "other-signrules")
  const signRuleCategories = [
    { rules: standardRules, header: t("signingrules") },
    { rules: prokuraRules, header: t("prokura") },
    { rules: otherRules, header: t("other-signrules") },
  ]
  return (
    <HorizontalStyle>
      <ContentWrapper>
        {signRuleCategories.map((category, index) => (
          <SignRuleOptions
            t={t}
            key={category.header}
            rules={category.rules}
            header={category.header}
            selectedRuleIndex={selectedRuleIndex}
            setSelectedRuleIndex={(index) => {
              updateRules(orgNr, rules, rules[index])
              setSelectedRuleIndex(index)
            }}
          />
        ))}
      </ContentWrapper>
      <ActiveSignRule
        rule={selectedRule}
        t={t}
        updateSelectedRule={(rule) => {
          rules[rule.index] = { ...rule }
          updateRules(orgNr, [...rules], rule)
          setSelectedRuleIndex(rule.index)
        }}
        isForeignAccount={isForeignAccount}
      />
    </HorizontalStyle>
  )
}

const HorizontalStyle = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`

const SelectSignersCollateral = ({ flow, task, t, schema, save, complete }) => {
  const [rulesByOrg, setRules] = useState(
    returnFirstArgWithValue(task.data?.rulesByOrg, task.defaults)
  )

  const [isProcessing, setProcessing] = useState(false)
  const [formData, setFormData] = useState(
    returnFirstArgWithValue(task.data?.formData) || {}
  )
  const [errors, setErrors] = useState([])
  const signingRules = useRef(
    returnFirstArgWithValue(task.data?.signingRules, {}) || {}
  )

  const signingRulesByOrg = task.defaults
  const { isForeignAccount } = task.context

  const updateRules = useCallback(
    (orgNr, signRules, selectedRule) => {
      signingRules.current[orgNr] = selectedRule
      rulesByOrg[orgNr].signRules = signRules
      setRules({ ...rulesByOrg })
      setErrors([])
    },
    [rulesByOrg]
  )

  const options = Object.keys(signingRulesByOrg).map((orgNr) => {
    return {
      id: orgNr,
      title: signingRulesByOrg[orgNr].name,
      component: (
        <SignRuleSelector
          key={orgNr}
          t={t}
          initialRule={signingRules.current[orgNr]}
          orgNr={orgNr}
          rules={rulesByOrg[orgNr].signRules}
          updateRules={updateRules}
          isForeignAccount={isForeignAccount}
        />
      ),
    }
  })

  const mapSaveData = () => ({
    rulesByOrg,
    signingRules: signingRules.current,
    formData,
  })

  const handleComplete = (e) => {
    e.preventDefault()

    const errors = []

    Object.keys(signingRulesByOrg).forEach((orgNr) => {
      if (!signingRules.current[orgNr]) {
        errors.push(
          `${t("you-must-choose-signing-rule-for")} ${
            signingRulesByOrg[orgNr].name
          }`
        )
        return
      }

      const signRule = signingRules.current[orgNr]
      signRule.personRolleKombinasjon.forEach((person) => {
        if (
          !person?.isValid &&
          (signRule?.kode !== "OTHER" || person?.selected)
        )
          errors.push(
            `${t("signatory")} ${person.navn} ${t("has-invalid-email")} ${
              signingRulesByOrg[orgNr].name
            }`
          )
      })
      if (
        signRule?.kode === "OTHER" &&
        !signRule.personRolleKombinasjon.some((person) => person.selected)
      )
        errors.push(
          `${t("choose-at-least-one-signatory-custom-rule")} ${
            signingRulesByOrg[orgNr].name
          }`
        )
    })

    if (errors.length > 0) {
      setErrors(errors)

      return
    }

    setProcessing(true)
    complete(
      { signRules: signingRules.current, ...formData },
      () => setProcessing(false),
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const handleSave = (e) => {
    e.preventDefault()
    setProcessing(true)
    save(
      mapSaveData(),
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const strippedSchema = {
    ...schema,
    properties: { comment: schema.properties.comment },
  }

  return (
    <Layout forceHeight>
      <TabWrapper>
        <StyledTabs options={options} />
      </TabWrapper>
      <Context flow={flow} context={task.context?.applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={strippedSchema}
            formData={formData}
            disabled={isProcessing}
            onChange={(values) => setFormData(values)}
          >
            <></>
          </ReactForm>
          <ButtonContainer>
            <PrimaryButton
              disabled={isProcessing}
              onClick={(e) => handleComplete(e)}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              disabled={isProcessing}
              onClick={(e) => handleSave(e)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
          {errors.length > 0 && (
            <StyledErrorNotice>
              {errors.map((text) => (
                <Error t={t} error={text} />
              ))}
            </StyledErrorNotice>
          )}
        </StyledForm>
      </Context>
    </Layout>
  )
}

const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`

const StyledErrorNotice = styled(Notice)`
  background-color: ${Colors.FerrariLightest};
  padding: 15px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  height: 100%;
  .content {
    max-height: 100%;
    overflow-y: unset;
  }
`

const LabelSpan = styled.span`
  padding: ${({ hasHelp }) => (hasHelp ? "0.02em" : "0.2em")};
  padding-bottom: 1em;
  ${FontStyles.Small};
  width: fit-content;
  display: flex;
  align-items: center;
`
const TabWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
`

const ContentWrapper = styled.div`
  overflow-y: auto;
  padding: 20px;
  border-right: 1px solid #e4e2e2;
  width: 35%;
`

const SignerContentDiv = styled.div`
  padding: 5px 30px;
`

const SignersInformationList = styled.div`
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
`

const RadioButtonWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 10px;
`

export default withTranslation()(SelectSignersCollateral)
