import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import React, { useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import WarningInfoBox from "../components/common/WarningInfoBox"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"

const ConsiderExtensionOfPayment = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
}) => {
  const taskHasData = task?.data && Object.keys(task?.data).length > 0
  const [formData, setFormdata] = useState(
    taskHasData ? task?.data : task?.defaults
  )
  const [isProcessing, setProcessing] = useState(false)

  const handleComplete = (values) => {
    setProcessing(true)

    complete(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = (values) => {
    setProcessing(true)

    save(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <LeftContent>
        <WarningInfoBox
          text={`${t("warning-consider-extension-of-payment")}`}
        />
      </LeftContent>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <StyledGeneratedForm>
          <ReactForm
            schema={schema || {}}
            formData={formData}
            disabled={isProcessing}
            onChange={(values) => setFormdata(values)}
            onSubmit={(values) => handleComplete(values)}
          >
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={isProcessing}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={handleSave}
              >
                {" "}
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledGeneratedForm>
      </Context>
    </Layout>
  )
}
export default ConsiderExtensionOfPayment

const StyledGeneratedForm = styled.div`
  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }
  label {
    label {
      span {
        padding-left: 10px;
        font: 12px "IBM Plex Sans";
        color: #6c6c6c;
      }
      input {
        background-color: blue;
        max-height: 12px;
        max-width: 12px;
        margin: 0;
      }
    }
  }
  span:first-child {
    ${FontStyles.NormalStrong};
    padding-bottom: 0.4em;
  }
`
const ButtonContainer = styled.div`
  margin-top: 1em;
  button:last-of-type {
    margin-left: 1em;
    border: solid 1px ${Colors.SeaLight};
  }

  button {
    padding: 0;
  }
`

const LeftContent = styled.div`
  display: grid;
  padding: 2em;
`
