import React, { useState, useEffect } from "react"
import styled from "styled-components"
import TextButton from "../../common/TextButton"
import CreateDecisionText from "./CreateDecisionText"
import lodash from "lodash"
import { addMissingDecisionFields } from "../../utils/mapMaintenance"
import DecisionText from "./DecisionText"
import { v4 as uuidv4 } from "uuid"

const AddManualDecisionText = ({
  t,
  onChange,
  delivery,
  deliveryId,
  agreements,
}) => {
  const [manualAdd, setManualAdd] = useState(false)

  const onAddDecision = (data, manual, selectedAgreements) => {
    const uuid = uuidv4()
    const newDelivery = lodash.cloneDeep(delivery)
    newDelivery.changes.push({
      id: uuid,
      type: "MANUAL",
      changeData: selectedAgreements.map((agreement) => ({
        type: "MANUAL",
        agreementData: agreement,
      })),
      decisionTexts: {
        userAdded: [data],
        generated: [],
      },
    })

    onChange({ deliveryId, data: newDelivery }) // Updates the whole delivery object with the newly added decision.
    setManualAdd(false)
  }

  return (
    <Container>
      {manualAdd ? (
        <CreateDecisionText
          t={t}
          onCancelEdit={() => setManualAdd(false)}
          onAddDecision={onAddDecision}
          agreements={agreements}
        />
      ) : (
        <TextButton padding={false} onClick={() => setManualAdd(!manualAdd)}>
          {t("add-text-manually")}
        </TextButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  border: 1px solid #e5edff;
  padding: 14px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #f5f8ff;
`

export default AddManualDecisionText
