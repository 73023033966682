import React, { useState, useEffect } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { Colors } from "@flow/style"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import InsightModule from "../components/insight/InsightModule"
import { checkForAuthority } from "../components/utils/checkForAuthority"
import { decisionLevels } from "../../constants/authorityDecisionLevels"
import AssessmentComments from "../components/common/AssessmentComments"
import { useInsightAppInitialized } from "../components/insight/useInsightAppInitialized"
import Tabs from "../components/common/Tabs"
import Notice from "../components/common/Notice"
import ErrorText from "../components/common/ErrorText"
import BlueDot from "../components/common/BlueDot"
import getInsightContextForCategory from "../util/getInsightContextForCategory"
import { getCurrency } from "../util/getCurrency"

const InitialAssessment = ({ task, flow, t, schema, save, complete, user }) => {
  const { data, defaults, context } = task

  const [activeTab, setActiveTab] = useState("analyze")
  const [isProcessing, setProcessing] = useState(false)
  const [formData, setFormData] = useState(data || defaults)
  const [app, setApp] = useState(null)
  const { applicationSummary, insightComponentData } = task.context
  const [allowedToDecide, setAllowedToDecide] = useState(undefined)
  const [allowedToDecideReason, setAllowedToDecideReason] = useState("")

  const [isLoading, isErrored] = useInsightAppInitialized(app)

  useEffect(() => {
    const decisionLevel =
      context?.queueStatusCurrentCase?.split("ASSESS_APPLICATION_")?.pop() || ""
    const allowedToDecide = checkForAuthority(
      user,
      context.signedUpForAssessmentBy,
      decisionLevels[decisionLevel]
    )
    setAllowedToDecide(allowedToDecide.allowed)
    setAllowedToDecideReason(allowedToDecide.reason)
  }, [user, context])

  const handleSave = () => {
    setProcessing(true)

    save(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)

    complete(
      values,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const initialAssessmentOptions = {
    context: getInsightContextForCategory(flow.data.application.category, true),
    defaultCurrency: getCurrency(flow.data.application.category),
  }

  const options = [
    {
      id: "analyze",
      title: t("overview"),
      component: (
        <InsightModule
          name={"@stacc/vue-initialassessment"}
          data={insightComponentData}
          commonData={insightComponentData?.commonData}
          onAppChange={setApp}
          options={initialAssessmentOptions}
        />
      ),
    },
  ]
  //only add tab if data
  const comments = flow?.data?.assessmentComments
  comments &&
    options.push({
      id: "assessments",
      title: t("assessment-history"),
      children: comments?.length ? (
        <BlueDot>{comments?.length}</BlueDot>
      ) : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments comments={flow?.data?.assessmentComments} t={t} />
        </AssessmentCommentsWrapper>
      ),
    })

  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <Tabs
          defaultTab="analyze"
          options={options}
          loading={isLoading}
          onChange={(tab) => setActiveTab(tab)}
        />
      </BMAnalyzeContent>
      <Context flow={flow} context={applicationSummary}>
        <StyledForm>
          {!allowedToDecide && (
            <p style={{ color: Colors.Ferrari, paddingBottom: "5px" }}>
              {t(allowedToDecideReason)}
            </p>
          )}
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing || !allowedToDecide}
            onChange={(values) => setFormData(values)}
            onSubmit={(values) => handleComplete(values)}
          >
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={
                  isProcessing || !allowedToDecide || isErrored || isLoading
                }
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing || !allowedToDecide}
                onClick={() => handleSave()}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
            {isErrored && (
              <StyledNotice backgroundColor={Colors.FerrariLighter}>
                {isErrored && <ErrorText>{t(`failed-to-load-task`)}</ErrorText>}
              </StyledNotice>
            )}
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}

const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`

const StyledNotice = styled(Notice)`
  color: ${Colors.Ferrari};
  border-radius: 0;
  margin-top: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`

const BMAnalyzeContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  overflow-y: auto;
`

const AssessmentCommentsWrapper = styled.div`
  padding: 10px 40px;
  overflow-y: auto;
`

export default withTranslation()(InitialAssessment)
