import React, { useMemo, useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import Layout from "../components/common/Layout"
import DisplaySigner from "../components/followUpCustomer/DisplaySigner"
import WarningInfoBox from "../components/common/WarningInfoBox"

import { returnFirstArgWithValue } from "../util/returnValue"
import formatWithLocale from "../util/dateFormatter"

const FollowUpCustomer = (props) => {
  const { task, t, schema, flow } = props
  const { data, defaults, context } = task
  const { applicationSummary } = context
  const { displayData } = defaults
  const currentSigning =
    flow?.data?.deliveries[task?.variables?.delivery?.id]?.signing?.current

  const acceptanceDeadline = currentSigning?.acceptanceDeadline
  let signDeadline = t("deadline-not-available")
  if (acceptanceDeadline)
    signDeadline = formatWithLocale(acceptanceDeadline, "dd.MM.yyyy")

  if (!Array.isArray(currentSigning?.signOrders)) {
    currentSigning.signOrders = [currentSigning?.signOrders]
  }

  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data, currentSigning?.followUpCustomer, {})
  )
  const [isProcessing, setProcessing] = useState(false)
  const onFormChange = (values) => {
    setFormData(values)
  }

  const handleSave = () => {
    setProcessing(true)
    props.save(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)
    props.complete(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <TabWrapper>
          <WarningInfoBox
            text={`${t("accept-deadline-soon")} ${t(
              "deadline"
            )}: ${signDeadline}`}
          />
          <SignerWrapper>
            {displayData?.map((signer, i) => {
              return (
                <DisplaySigner
                  key={i}
                  t={t}
                  signer={signer}
                  status={signer.status}
                />
              )
            })}
          </SignerWrapper>
        </TabWrapper>
      </BMAnalyzeContent>

      <Context flow={flow} context={applicationSummary}>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  justify-content: flex-end;
  margin-right: 1.9em;
`

const BMAnalyzeContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
  overflow-y: auto;
`

const TabWrapper = styled.div`
  margin: 0px 40px;
  margin-top: 10px;
`

const SignerWrapper = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export default withTranslation()(FollowUpCustomer)
