import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import WarningInfoBox from "../components/common/WarningInfoBox"
import BeginPayoutDetails from "../components/BeginPayout/BeginPayoutDetails"
import { Colors } from "@flow/style"
import { returnFirstArgWithValue } from "../util/returnValue"
import { Date as FlowDate } from "@flow/forms"
import formatWithLocale from "../util/dateFormatter"
import { checkForDepotPayoutAuthority } from "../components/utils/checkForAuthority"
import { v4 as uuidv4 } from "uuid"
import { addThousandSeparator } from "../util/addThousandSeparator"
import { FLOW_NS_DEV_OR_TEST } from "../components/ONLY_RENDER_IN_DEVELOPMENT"
import { getCurrency } from "../util/getCurrency"

const BeginPayout = ({ task, flow, t, schema, save, complete, user }) => {
  const { data, defaults } = task
  const deliveryId = task.variables.delivery.id
  /**
   * @type {InFlow.Delivery}
   */
  const currentDelivery = flow?.data?.deliveries[deliveryId]
  /**
   * @type {InFlow.PerformFinancialCheck}
   */
  const stateDataFromTask =
    currentDelivery?.currentPayout?.performFinancialCheck || {}
  const stateData = stateDataFromTask

  const { payoutRequestReceived, commitment, performFinancialCheck } =
    currentDelivery?.currentPayout
  const { payoutType } = payoutRequestReceived

  const [isProcessing, setProcessing] = useState(false)
  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data, { comment: task?.context?.comment }, defaults)
  )
  const [allowedToDecide, setAllowedToDecide] = useState(undefined)
  const [allowedToDecideReason, setAllowedToDecideReason] = useState("")

  const performingFinancialCheck = task.context.performFinancialCheck
  const currency = getCurrency(flow.data.application.category)

  // matil-saker med forskuddsutbetaling har ikkje kjørt igjennom øk.kontroll og anvis utbet.
  const hasAbroadPayments = stateData?.payments?.some(
    (payment) => payment.payoutApplicantOrThirdPerson === "abroad"
  )
  useEffect(() => {
    if (performingFinancialCheck) {
      const fetchData = async () => {
        const data = await checkForDepotPayoutAuthority(
          user,
          task.taskType,
          flow.flowId
        )
        if (FLOW_NS_DEV_OR_TEST()) {
          setAllowedToDecide(true)
          setAllowedToDecideReason("Dev or test")
        } else {
          setAllowedToDecide(data.allowed)
          setAllowedToDecideReason(data.reason)
        }
      }

      fetchData()
    } else {
      //Ingen validering dersom det ikke skal innom Økonomisk kontroll
      //Gjelder produkter som MATIL
      setAllowedToDecide(true)
    }
  }, [user])

  let remainingAmount, totalPayoutAmount

  if (stateData.hasOwnProperty("totalPayoutAmount")) {
    totalPayoutAmount = stateData.totalPayoutAmount
  } else {
    if (
      stateData?.payments &&
      stateData?.payments?.length > 0 &&
      !stateData?.payments?.some((payment) => payment.payoutAmount)
    ) {
      totalPayoutAmount = Number(payoutRequestReceived.payoutAmount)
    } else {
      totalPayoutAmount = stateData?.payments?.reduce((acc, payment) => {
        return acc + (Number(payment?.payoutAmount) || 0)
      }, 0)
    }

    stateData.totalPayoutAmount = totalPayoutAmount
  }

  // All new payments have totalPayoutAmount and remainingAmount
  if (stateData.hasOwnProperty("remainingAmount")) {
    remainingAmount = stateData.remainingAmount
  } else {
    remainingAmount =
      Number(commitment?.accountByAccountName?.availableAmount) -
      Number(
        totalPayoutAmount
          ? totalPayoutAmount
          : payoutRequestReceived.payoutAmount
      )
    stateData.remainingAmount = remainingAmount
  }

  let lastUpdatedPayoutType
  if (stateData.payoutType !== payoutType) {
    lastUpdatedPayoutType = stateData.payoutType
  } else {
    lastUpdatedPayoutType = payoutType
  }
  const customerAccount = flow?.data.raw?.accountMaster?.customerAccount || {}
  const applicantName = customerAccount?.name
  if ((stateData?.payments?.length ?? 0) === 0) {
    stateData.payments = [
      {
        customerAccountNumber: payoutRequestReceived?.customerAccountNumber,
        payoutName: applicantName,
        id: uuidv4(),
        payoutOrgNumber: flow.data.raw.accountMaster.customerAccount.publicId,
        isPerson:
          flow.data.raw.accountMaster.customerAccount.accountType === "Person",
        payoutApplicantOrThirdPerson: "applicant",
        payoutAmount: performingFinancialCheck
          ? performFinancialCheck.totalPayoutAmount
          : Number(payoutRequestReceived?.content?.payoutAmount),
      },
    ]
  }

  const showWarningText =
    lastUpdatedPayoutType === "finalPayout" && remainingAmount > 0

  const [dateError, setDateError] = useState(false)

  const handleComplete = (values) => {
    //dateValidation

    const selectedPayoutDate = values?.payoutDate
    const currentDate = formatWithLocale(new Date(), "yyyy-MM-dd")

    values.stateData = stateData
    values.stateData.payoutType = lastUpdatedPayoutType || payoutType

    if (
      values.decision === "OK" &&
      (!selectedPayoutDate || selectedPayoutDate < currentDate)
    ) {
      setDateError(true)
      setProcessing(false)
    } else {
      setDateError(false)
      setProcessing(true)
      complete(
        values,
        () => setProcessing(false),
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    }
  }

  const handleSave = (e) => {
    e.preventDefault()
    setProcessing(true)
    save(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <MainContent>
        {hasAbroadPayments ? (
          <WarningInfoBox text={t(`begin-payout-text-about-manual-payouts`)} />
        ) : (
          <WarningInfoBox
            color={"inherit"}
            text={t(`begin-payout-information-text-body`)}
            padding={showWarningText ? "1em 0 0 0" : "1em 0 2em 0"}
          />
        )}
        {showWarningText && (
          <WarningInfoBox
            textColor={Colors.Ferrari}
            color={"inherit"}
            text={
              <>
                <b>{t("begin-payout-warning-text-obs")}</b>
                {t("begin-payout-warning-text-body")} (
                {addThousandSeparator(remainingAmount) + ` ${currency}`})
              </>
            }
            padding={"1em 0 2em 0"}
          />
        )}
        <BeginPayoutDetails
          flow={flow}
          t={t}
          stateData={stateData}
          deliveryId={deliveryId}
          currentDelivery={currentDelivery}
          payoutRequestReceived={payoutRequestReceived}
          commitment={commitment}
          remainingAmount={remainingAmount}
          readonly
        />
      </MainContent>
      <Context flow={flow} context={task.context?.applicationSummary}>
        {!allowedToDecide && (
          <p style={{ color: Colors.Ferrari, paddingBottom: "5px" }}>
            {t(allowedToDecideReason)}
          </p>
        )}
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing || !allowedToDecide}
            onSubmit={(values) => handleComplete(values)}
            onChange={(values) => setFormData(values)}
          >
            {formData.decision === "OK" && (
              <FlowDate
                name={"payoutDate"}
                id={"payoutDate"}
                label={"Sett utbetalingsdato"}
                onChange={(e) =>
                  setFormData({ ...formData, payoutDate: e.target.value })
                }
                min={formatWithLocale(new Date(), "yyyy-MM-dd")}
                value={formData?.payoutDate}
              />
            )}
            {dateError && (
              <WarningInfoBox
                textColor={Colors.Ferrari}
                color={"inherit"}
                text={t("date-not-valid")}
                padding={"1em 0 2em 0"}
              />
            )}
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={isProcessing || !allowedToDecide}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={(e) => handleSave(e)}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default withTranslation()(BeginPayout)

const MainContent = styled.div`
  flex: 1;
  border-right: 1px solid #e4e2e2;
  overflow-y: auto;
  padding: 30px 40px;
  min-width: 40em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`
