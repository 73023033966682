import React from "react"
import Notice from "../../common/Notice"
import { Icons } from "@flow/icons"
import StyledBox from "../../common/StyledBox"
import styled from "styled-components"
import { Colors } from "@flow/style"
import DisplayCreditCheckStakeholder from "./DisplayCreditCheckStakeholder"
import DisplayCadastreInfo from "./DisplayCadastreInfo"
import DisplayPopulationRegistryInfo from "./DisplayPopulationRegistryInfo"

const StakeholderIcon = ({ type }) => {
  switch (type) {
    case "person":
      return <Icons.StakeholderPerson size={36} />
    case "organization":
      return <Icons.StakeholderOrganization size={36} />
    default:
      return <></>
  }
}

const Item = ({ title, data }) =>
  data ? (
    <div>
      <StyledBox
        whiteSpace
        padding="0 10px"
        title={title}
        data={data}
        large={false}
      />
    </div>
  ) : (
    <></>
  )

const DisplayStakeholder = ({
  stakeholder = {},
  t,
  color,
  onEdit,
  readonly,
  isEditing,
}) => {
  const hasContactPersonDetails =
    stakeholder.contactPersonEmail || stakeholder.contactPersonName

  return (
    <StakeholderCardContainer>
      <Notice backgroundColor={color}>
        {stakeholder.isSpouse && <p>{t("this-is-spouse")}</p>}
        {stakeholder.isApplicant && <p>{t("this-is-applicant")}</p>}
        {stakeholder.isCoDebtor && <p>{t("coDebtor")}</p>}
        {stakeholder.isGuarantor && <p>{t("this-is-guarantor")}</p>}
        {stakeholder.isRealSurety && <p>{t("this-is-realSurety")}</p>}
        <div className="top-section">
          <div className="icon">
            <StakeholderIcon type={stakeholder.type} />
          </div>
          <div className="content">
            <Item
              key={"name"}
              title={t("name")}
              data={stakeholder.name}
              large={false}
            />
            {stakeholder.type === "person" && (
              <>
                <Item key={"ssn"} title={t("ssn")} data={stakeholder.ssn} />
                <Item
                  key={"email"}
                  title={t("email")}
                  data={stakeholder.email}
                />
                {stakeholder.isMarried && (
                  <>
                    <Item
                      key={"isMarried"}
                      title={t("is-married")}
                      data={stakeholder.isMarried ? "Ja" : "Nei"}
                    />
                    <Item
                      key={"spouseSsn"}
                      title={t("spouseSsn")}
                      data={stakeholder.spouseSsn}
                    />
                  </>
                )}
              </>
            )}
            {stakeholder.type === "organization" && (
              <>
                <Item
                  key={"organizationNumber"}
                  title={t("organizationNumber")}
                  data={stakeholder.organizationNumber}
                />

                <Item
                  key={"contactPerson"}
                  title={t("contactPerson")}
                  data={
                    hasContactPersonDetails
                      ? `${stakeholder.contactPersonName}, ${stakeholder.contactPersonEmail}`
                      : "Mangler kontaktinformasjon"
                  }
                />
              </>
            )}
          </div>
          {!isEditing && !readonly && (
            <div className="options">
              <div onClick={onEdit}>
                <Icons.Edit color={Colors.Sea} size="26" />
              </div>
            </div>
          )}
        </div>
        <DisplayCreditCheckStakeholder stakeholder={stakeholder} t={t} />
        <DisplayCadastreInfo
          ownedCadastres={stakeholder.ownedCadastres}
          displayNone={
            stakeholder.type === "organization" &&
            stakeholder.scope === "international"
          }
          t={t}
        />
        <DisplayPopulationRegistryInfo
          populationRegistryInfo={stakeholder.nationalPopulationRegister}
          displayNone={stakeholder.type === "organization"}
          t={t}
        />
      </Notice>
    </StakeholderCardContainer>
  )
}

const StakeholderCardContainer = styled.div`
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0px 10px;
    align-items: flex-start;
    flex: 1;
  }

  .top-section {
    display: flex;
    flex-direction: row;
    margin: 10px 10px;
  }

  .edit-card {
    width: 100%;
    padding: 10px 10px;
  }

  .content-item {
    min-width: 200px;
  }

  .full-width {
    width: 100%;
  }

  .edit-form {
    margin-top: 10px;
    display: flex;
    width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .edit-form > * {
    width: calc(50% - 20px);
  }

  .options {
    margin-top: 10px;
  }

  .options > div {
    cursor: pointer;
  }

  .icon {
    padding-top: 10px;
  }

  .person-content {
    display: grid;
    padding: 10px 10px;
    grid-template-columns: minmax(200px, auto) 180px minmax(200px, auto) 200px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .edit-content {
    display: grid;
    grid-template-columns: 200px minmax(200px, auto);
    align-items: center;
    gap: 10px;
  }

  .organization-content {
    display: grid;
    padding: 10px 10px;
    grid-template-columns: minmax(200px, auto) 200px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .stakeholder-card-content {
    margin-left: 65px;
    margin-bottom: 10px;
  }
`

export default DisplayStakeholder
