import React, { useState } from "react"
import styled from "styled-components"
import AccountForPayout from "./AccountForPayout/AccountForPayout"
import { Colors, FontStyles } from "@flow/style"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import { Input } from "@staccx/bento"
import WarningInfoBox from "../../common/WarningInfoBox"
import GridContent from "./GridContent"
import { Spinner } from "@flow/icons"
import { PersonOrThirdRadio } from "./personOrThirdRadio"
import IconButton from "../../common/IconButton"
import axios from "axios"
import { hasData } from "../../../util/returnValue"
import { getKarErrorMessage } from "../../utils/mapPayout"

/**
 * @typedef {object} PayoutDetailsFormProps
 * @property {InFlow.Delivery} currentDelivery - currentDelivery
 * @property {InFlow.Commitment} commitment - commitment
 * @property {InFlow.StateData} stateData - stateData
 * @property {Function} setStateData - setStateData
 * @property {Function} onDelete - onDelete
 * @property {Function} updateCurrentPayment - (payment) => updateCurrentPayment(payment)
 * @property {InFlow.Payment} payment - payment
 * @property {InFlow.PayoutRequestReceived} payoutRequestReceived - payoutRequestReceived
 * @property {boolean} isPerformFinancialCheck - isPerformFinancialCheck
 * @property {InFlow.KarCheckMessage} karCheckMessage - karCheckMessage
 * @property {string} flowId - flowId
 * @property {string} deliveryId - deliveryId
 * @property {boolean} readonly - readonly
 * @property {boolean} partialSave - partialSave
 * @property {object} validationError - validationError
 * @property {number} index - index
 * @property {object} customerAccount - customerAccount
 * @property {Function} t - t
 */

/** @param {PayoutDetailsFormProps} props */
const PayoutDetailsForm = ({
  currentDelivery,
  flowId,
  karCheckMessage,
  commitment,
  t,
  readonly,
  partialSave,
  validationError,
  deliveryId,
  onDelete,
  updateCurrentPayment,
  payment,
  index,
  payoutRequestReceived,
  customerAccount,
}) => {
  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @param {InFlow.PayoutTypes} key
   * @returns {Promise<void>}
   */
  const onChangePaymentField = async (e, key) => {
    if (readonly) return
    if (key === "payoutAmount") {
      const value = parseInt(e.rawValue)
      payment[key] = value
      updateCurrentPayment(payment)
      return
    }

    if (key === "payoutType") {
      const value = e.target.value
      payment[key] = value
      updateCurrentPayment(payment)
      return
    }
    if (key === "payoutName") {
      const value = e.target.value
      payment[key] = value
      updateCurrentPayment(payment)
      return
    }
    if (key === "payoutOrgNumber") {
      const value = e.target.value
      payment[key] = value
      updateCurrentPayment(payment)

      const isPerson = value?.length === 11
      payment.isPerson = isPerson

      if (value?.length === 11 || value?.length === 9) {
        payment.payoutName = await checkDaName(value)
        updateCurrentPayment(payment)
      }
      return
    }
    if (key === "payoutApplicantOrThirdPerson") {
      const value = e.target.value
      payment[key] = value
      updateCurrentPayment(payment)
      return
    }
    if (key === "bicSwiftNumber") {
      const value = e.target.value
      payment[key] = value
      updateCurrentPayment(payment)
      return
    }
  }

  const checkDaName = async (searchString) => {
    if (searchString.length === 11) {
      const response = await axios.get(
        `/api/applicantsName?applicantSSN=${searchString}`
      )
      return response?.data?.name
    }
    if (searchString.length === 9) {
      const response = await axios.get(
        `/api/organizationName?orgNumber=${searchString}`
      )
      return response?.data
    }
    return ""
  }

  const checkValid = (error, t) => {
    return (
      error === "Konto finnes og eies av angitt kunde." ||
      error === t("wait-karcheck-message")
    )
  }
  const [KARMessage, setKARMessage] = useState(
    getKarErrorMessage(currentDelivery, payment?.id)
  )

  const [paymentReceiverType, setPaymentReceiverType] = useState(
    payment?.payoutApplicantOrThirdPerson || "applicant"
  )

  const updatePaymentReceiverType = (value) => {
    if (value.target.value === "thirdParty") {
      payment = {
        id: payment?.id,
        payoutAmount: payment?.payoutAmount,
        payoutName: "",
        payoutOrgNumber: "",
        customerAccountNumber: null,
        payoutApplicantOrThirdPerson: "thirdParty",
        karErrorMessage: null,
      }
      setKARMessage(t("no-karcheck"))
      updateCurrentPayment(payment)
    } else if (value.target.value === "applicant") {
      payment = {
        id: payment?.id,
        payoutAmount: payment?.payoutAmount,
        payoutName: customerAccount?.name, //applicantName,
        payoutOrgNumber: customerAccount?.publicId,
        customerAccountNumber: payoutRequestReceived?.customerAccountNumber,
        payoutApplicantOrThirdPerson: "applicant",
        //karCheckMessage: null,
      }
      setKARMessage(t("no-karcheck"))
      updateCurrentPayment(payment)
    } else if (value.target.value === "abroad") {
      payment = {
        id: payment?.id,
        payoutAmount: payment?.payoutAmount,
        payoutName: "",
        payoutOrgNumber: "",
        customerAccountNumber: null,
        payoutApplicantOrThirdPerson: "abroad",
        bicSwiftNumber: null,
      }
      setKARMessage(t("no-karcheck"))
      updateCurrentPayment(payment)
    }
    setPaymentReceiverType(value.target.value)
  }
  const isThirdParty = paymentReceiverType === "thirdParty"
  const isAbroad = paymentReceiverType === "abroad"

  const isBirthNumber = payment?.payoutOrgNumber?.length === 11
  const isOrgNumber = payment?.payoutOrgNumber?.length === 9

  const [mode, setMode] = useState("none")

  const isKarChecked =
    mode === "none" && (payment?.customerAccountNumber?.length ?? 0) >= 11
  const isNameFilled = hasData(payment?.payoutName)
  return (
    <>
      <Content>
        <FlexLine>
          <PersonOrThirdRadio
            t={t}
            handleChange={(e) => updatePaymentReceiverType(e)}
            current={paymentReceiverType}
            value={payment?.payoutApplicantOrThirdPerson}
            readonly={readonly}
          />
          <IconButton
            onClick={() => onDelete()}
            icon="Trashcan"
            disabled={readonly}
          />
        </FlexLine>
        <GridLine>
          {!readonly && (isThirdParty || isAbroad) ? (
            <InputStyled
              name="payout-orgnumber"
              id="payout-orgnumber"
              inputMode="numeric"
              label={t("payout-identifier")}
              disabled={readonly || (isKarChecked && !isAbroad)}
              value={payment?.payoutOrgNumber}
              onChange={(e) => onChangePaymentField(e, "payoutOrgNumber")}
            />
          ) : (
            <GridContent
              title={t("payout-identifier")}
              data={payment?.payoutOrgNumber}
              contentProps={{ color: Colors.Coal }}
            />
          )}
          <AccountForPayout
            key={payment?.payoutApplicantOrThirdPerson}
            class="account"
            flowId={flowId}
            readonly={readonly}
            data={payment?.customerAccountNumber}
            partialSave={partialSave}
            updateCurrentPayment={updateCurrentPayment}
            setErrorMessage={setKARMessage}
            deliveryId={deliveryId}
            t={t}
            payment={payment}
            index={index}
            mode={mode}
            setMode={setMode}
            isAbroad={isAbroad}
          />
          {!isAbroad ? (
            <GridContent
              title={t("account-validation")}
              contentProps={{
                color: !checkValid(KARMessage, t) && Colors.Ferrari,
              }}
              data={() => (
                <KARContainer>
                  {KARMessage === t("wait-karcheck-message") && (
                    <>
                      <Spinner size={24} />
                      <Margin right="5px" display="inline" />
                    </>
                  )}
                  {KARMessage}
                </KARContainer>
              )}
            ></GridContent>
          ) : null}
        </GridLine>
        <GridLine>
          <div>
            {!readonly && (isThirdParty || isAbroad) ? (
              <InputStyled
                name="payout-name"
                id="payout-name"
                inputMode="numeric"
                type="text"
                label={t("payout-name")}
                disabled={
                  (readonly ||
                    (isBirthNumber && isNameFilled) ||
                    (isOrgNumber && isNameFilled) ||
                    isKarChecked) &&
                  !isAbroad
                }
                value={payment?.payoutName}
                onChange={(e) => onChangePaymentField(e, "payoutName")}
              />
            ) : (
              <GridContent
                title={t("payout-name")}
                data={payment?.payoutName}
                contentProps={{ color: Colors.Coal }}
              />
            )}
          </div>
          <div>
            {!readonly ? (
              <InputStyled
                name="payout-amount"
                id="payout-amount"
                mode="currency"
                inputMode="numeric"
                pattern={/[0-9]*/}
                type="text"
                label={t("payout-amount")}
                disabled={readonly}
                value={addThousandSeparator(payment?.payoutAmount ?? 0)}
                onChange={(e) => onChangePaymentField(e, "payoutAmount")}
              />
            ) : (
              <GridContent
                title={t("payout-amount")}
                data={addThousandSeparator(payment?.payoutAmount ?? 0)}
                contentProps={{ color: Colors.Coal }}
              />
            )}
            {commitment?.accountByAccountName.openFee > 0 && (
              <WarningInfoBox
                padding="15px"
                width="182px"
                margin="0.5em 0em 0em 0em"
                text={t("establishment-fee-text")}
                fontSize={FontStyles.Small}
              />
            )}
            {validationError?.payoutAmount && (
              <ErrorField> {validationError?.payoutAmount}</ErrorField>
            )}
          </div>
          <div>
            {!readonly && isAbroad ? (
              <InputStyled
                name="bic-swift"
                id="bic-swift"
                label={t("bic-swift")}
                onChange={(e) => onChangePaymentField(e, "bicSwiftNumber")}
                value={payment?.bicSwiftNumber}
              />
            ) : isAbroad ? (
              <GridContent
                title={t("bic-swift")}
                data={payment?.bicSwiftNumber}
                contentProps={{ color: Colors.Coal }}
              />
            ) : null}
          </div>
        </GridLine>
      </Content>
    </>
  )
}
export default PayoutDetailsForm

const Content = styled.div`
  background-color: ${Colors.SeaLighter};
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
`
const GridLine = styled.div`
  padding: 0.5em 2em 1em 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
  grid-gap: 5em;
  align-items: center;
`
const FlexLine = styled.div`
  padding: 0.5em 2em 1em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const InputStyled = styled(Input)`
  input {
    margin: 0;
    border: solid 1px ${Colors.Grey2};
    width: 200px;
    border-radius: 0.4em;
    padding: 0.5em;
  }

  label {
    font-size: 12px;
    line-height: 1.4em;
    letter-spacing: 0.01em;
    padding: 0.2em;
  }
`

const KARContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Margin = styled.div`
  display: ${({ display }) => display || "block"};
  margin-left: ${({ left }) => left || "0px"};
  margin-top: ${({ top }) => top || "0px"};
  margin-right: ${({ right }) => right || "0px"};
  margin-bottom: ${({ bottom }) => bottom || "0px"};
`

const ErrorField = styled.span`
  color: ${Colors.Ferrari};
`

/**
 * @typedef {InFlow.Payment} Payment
 */

/**
 * @typedef {InFlow.PayoutRequestReceived} PayoutRequestReceived - The payout request received
 */
