import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Spinner } from "@flow/icons"
import axios from "axios"
import { withTranslation } from "react-i18next"

const getUrlParamAndFlow = async (flowDefinitionId) => {
  let oidcKey
  for (const key in sessionStorage) {
    if (key.startsWith("oidc.user:")) {
      oidcKey = key
      break
    }
  }

  const { access_token } = JSON.parse(sessionStorage.getItem(oidcKey))
  let params = {}
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      params[key] = value
    }
  )

  if (params) {
    try {
      const searchParams = new URLSearchParams({
        "data.application.applicationId": params?.applicationId ?? "",
        "data.application.caseNumber": params?.caseNumber ?? "",
        flowId: params?.flowId ?? "",
      })
      const { data: flows } = await axios.get(
        `api/flow/flows?flowDefinitionId=${flowDefinitionId}&view=customRoutePortalView&${searchParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )

      // Note: This should only return one in real cases since applicaitonId and caseNumber is uniquely tied to one flow.
      // This will not work properly in dev/test as we use the same applicationId / caseNumber for multiple cases.
      if (flows?.length === 1) {
        return { flowFound: true, flowId: flows[0].flowId }
      } else {
        return { flowFound: false, flowId: "" }
      }
    } catch (error) {
      console.error("Failed to fetch flow", error)
    }
    return { flowFound: false, flowId: "" }
  }
}

const findFlowInFlowDefinition = async (flowDefinitions) => {
  const results = await Promise.all(flowDefinitions.map(getUrlParamAndFlow))
  return results.find((x) => x.flowFound) || results[0]
}

const DEFINITION_IDS = [
  "application",
  "vekstgaranti",
  "maintenance",
  "delivery-payout",
]

const CustomRouteApplicationByCaseNumber = ({ t }) => {
  const [flowData, setFlowId] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const asyncFunc = async () => {
      const result = await findFlowInFlowDefinition(DEFINITION_IDS)

      if (result?.flowFound)
        window.location.assign(`/cases/case/application/${result?.flowId}`)

      setFlowId(result)
      setLoading(false)
    }
    asyncFunc()
  }, [])

  return (
    <LandingFrame>
      {loading && (
        <SpinnerFrame>
          <p>{t("looking-for-case")}</p>
          <Spinner />
        </SpinnerFrame>
      )}
      {!loading && flowData?.flowFound === false && (
        <SpinnerFrame>
          <p>{t("could-not-find-case-try-search")}</p>
        </SpinnerFrame>
      )}
    </LandingFrame>
  )
}

const LandingFrame = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`

const SpinnerFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  p {
    padding-bottom: 0.5em;
  }
`

CustomRouteApplicationByCaseNumber.propTypes = {}
export default withTranslation()(CustomRouteApplicationByCaseNumber)
