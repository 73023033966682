import styled from "styled-components"
import { Icons, Spinner } from "@flow/icons"
import StyledBox from "../common/StyledBox"
import IconButton from "../common/IconButton"
import { ProjectColors } from "../common/style/ProjectColors"

const DisplaySigner = ({ signer, t, onEditing, handleDelete }) => {
  return (
    <Container>
      {signer.isFetchingSignerDetails && (
        <SpinnerFrame>
          <Spinner />
        </SpinnerFrame>
      )}
      <SignerDetails>
        <SignerIcon type={signer.type} />
        <StyledBox title={t("name")} data={signer.name} />
        {signer.type === "organization" ? (
          <StyledBox
            title={t("organizationNumber")}
            data={signer.organizationNumber}
          />
        ) : (
          <StyledBox title={t("ssn")} data={signer.ssn} />
        )}
        {!signer.email ? (
          <StyledBox
            title={t("contact-information")}
            data={"Mangler informasjon"}
          />
        ) : (
          <StyledBox title={t("contact-information")} data={signer.email} />
        )}
        <AlignRight>
          <IconButton icon={"Trashcan"} onClick={() => handleDelete(signer)} />
          <IconButton icon={"Edit"} onClick={onEditing} />
        </AlignRight>
      </SignerDetails>
    </Container>
  )
}

const SpinnerFrame = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 249, 255, 0.8);
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color ${ProjectColors.Sea97};
  padding: 15px;
  border-radius: 8px;
`

const SignerDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const AlignRight = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 10px;
`

const SignerIcon = ({ type }) => {
  switch (type) {
    case "person":
      return <Icons.StakeholderPerson size={36} />
    case "organization":
      return <Icons.StakeholderOrganization size={36} />
    default:
      return <></>
  }
}

export default DisplaySigner
