import React from "react"
import { TransparentButton } from "@flow/buttons"
import { Icons } from "@flow/icons"
import { Colors } from "@flow/style"

const IconButton = ({ icon, disabled, onClick, color, className }) => {
  const Icon = Icons[icon]
  return (
    <TransparentButton
      className={className}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon color={color || (disabled ? Colors.Grey : Colors.Sea)} />
    </TransparentButton>
  )
}

export default IconButton
