import styled from "styled-components"
import { Tabs } from "@flow/components"
import RequestedDocArchive from "../common/RequestedDocArchive"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import Details from "./Details"
import BisnodeCreditLookup from "../performFinancialCheck/detailsComponents/BisnodeCreditLookup"
import AssessmentComments from "../common/AssessmentComments"
import InsightModule from "../insight/InsightModule"
import ApplicationHistory from "../PerformIntroductoryEvaluation/ApplicationHistory"
import BlueDot from "../common/BlueDot"
import SpecialTermsTab from "../../components/AnalyzeApplication/SpecialTermsTab"

const FinancialCheckTab = ({
  flow,
  t,
  stateData,
  setStateData,
  partialSave,
  validationError,
  task,
  readonly,
  deliveryId,
  commonData,
  isPerformFinancialCheck,
}) => {
  const [activeTab, setActiveTab] = useState("details")
  const thisDeliveryAsssessments = flow?.data?.assessmentComments.filter(
    (x) => !x.deliveryId || x.deliveryId === deliveryId
  )
  const options = [
    {
      id: "details",
      title: t("details"),
      component: (
        <Details
          deliveryId={deliveryId}
          flow={flow}
          readonly={readonly}
          t={t}
          stateData={stateData}
          partialSave={partialSave}
          setStateData={setStateData}
          validationError={validationError}
          isPerformFinancialCheck={isPerformFinancialCheck}
        />
      ),
    },
    {
      id: "bisnode",
      title: t("bisnode"),
      component: (
        <BisnodeCreditLookup
          flow={flow}
          deliveryId={deliveryId}
          t={t}
          task={task}
        />
      ),
    },
    {
      id: "insight-modul",
      title: t("accounting"),
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          options={{
            readOnly: readonly,
            context: "accounting",
          }}
          commonData={commonData}
        />
      ),
    },
    {
      id: "applicationHistory",
      title: t("application-history"),
      component: (
        <ApplicationHistory
          applications={
            flow.data?.raw?.applicationMaster?.previousCases?.makePayout
          }
        />
      ),
    },
    {
      id: "specialconditions",
      title: t("specialTerms"),
      component: (
        <SpecialTermsTab
          deliveries={flow?.data?.deliveries}
          isPayout={true}
          previousApplications={
            flow?.data?.raw?.applicationMaster?.previousCases.makePayout
          }
          dateOfApproval={flow?.data?.decision?.dateOfApproval}
          onChange={() => {}}
          t={t}
          readOnly={true}
          currentDeliveryId={deliveryId}
        />
      ),
    },
    {
      id: "assessment",
      title: t("assessment-history"),
      children: thisDeliveryAsssessments.length ? (
        <BlueDot>{thisDeliveryAsssessments.length}</BlueDot>
      ) : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments comments={thisDeliveryAsssessments} t={t} />
        </AssessmentCommentsWrapper>
      ),
    },
    {
      id: "comments",
      title: t("requested-documentation"),
      component: (
        <RequestedDocArchive
          documentations={
            flow?.data?.deliveries[task?.variables.delivery.id]?.currentPayout
              ?.updateDocumentation?.documentation
          }
          t={t}
        />
      ),
    },
  ]

  return (
    <Content>
      <Tabs options={options} onChange={(e) => setActiveTab(e)} />
      <TabContainer>
        {options.map((x, i) => (
          <div
            key={i}
            style={{ display: x.id === activeTab ? "block" : "none" }}
          >
            {x.component}
          </div>
        ))}
      </TabContainer>
    </Content>
  )
}

export default withTranslation()(FinancialCheckTab)
const Content = styled.div`
  flex: 1;
  padding: 10px 40px;
  overflow-y: auto;
`
const TabContainer = styled.div`
  margin: 20px 0;
  flex: 1;
`

const AssessmentCommentsWrapper = styled.div`
  padding: 10px 40px;
  overflow-y: auto;
`
