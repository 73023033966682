import styled from "styled-components"
import React from "react"
import { useState } from "react"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { returnFirstArgWithValue } from "../util/returnValue"
import AvailableFramesTable from "../components/evaluateBasisForSupport/AvailableFramesTab/AvailableFramesTable"
import { withTranslation } from "react-i18next"

const EvaluateMissingFrames = ({ flow, t, schema, task, complete, save }) => {
  const { context, data, defaults } = task

  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data, defaults, {})
  )
  const [isProcessing, setProcessing] = useState(false)
  const onFormChange = (values) => {
    setFormData(values)
  }

  const availableFrames = flow.data.decision.availableFrames

  const handleSave = () => {
    setProcessing(true)
    save(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)
    complete(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <AvailableFramesTable
          availableFrames={availableFrames}
          t={t}
          isInDecision={true}
        ></AvailableFramesTable>
      </BMAnalyzeContent>
      <Context flow={flow} context={context.applicationSummary}>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}

export default withTranslation()(EvaluateMissingFrames)

const BMAnalyzeContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
  overflow-y: auto;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
