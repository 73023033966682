import React, { useState, useMemo } from "react"
import styled from "styled-components"
import { FlexRow } from "../ControlComponents"
import { Colors } from "@flow/style"
import DecisionText from "./DecisionText"
import {
  generateDecisionText,
  getTitle,
  removeDecision,
} from "../../utils/mapMaintenance"
import lodash from "lodash"
import { v4 as uuidv4 } from "uuid"
import CreateDecisionText from "./CreateDecisionText"

const Change = ({
  t,
  change,
  delivery,
  deliveryId,
  context,
  onChange,
  insightMappedStakeholder,
  agreements,
}) => {
  const [manualAdd, setManualAdd] = useState(false)
  const generateDecisions = () => {
    const decision = generateDecisionText(
      change,
      context,
      t,
      insightMappedStakeholder,
      agreements
    )

    // Don't add text if already existing.
    if (
      change.decisionTexts?.generated?.find(
        (generated) => generated.decision?.text === decision.text
      )
    )
      return

    return onAddDecision(
      {
        id: uuidv4(),
        allowModification: decision.allowModification,
        decision: {
          title: decision.title,
          text: decision.text,
        },
      },
      false
    )
  }

  const onAddDecision = (data, manual) => {
    setManualAdd(false)
    const newDelivery = lodash.cloneDeep(delivery)

    const newDeliveryChange = newDelivery.changes.find(
      (c) => c.id === change.id
    )

    if (data?.isModified) {
      // Remove the "old" unmodified decision first.
      newDeliveryChange.decisionTexts.generated =
        newDeliveryChange.decisionTexts.generated.filter(
          (d) => d.id !== data.id
        )
      delete data.allowModification
    }

    if (manual) {
      newDeliveryChange.decisionTexts.userAdded.push(data)
    } else {
      newDeliveryChange.decisionTexts.generated.push(data)
    }
    onChange({ deliveryId, data: newDelivery }) // Updates the whole delivery object with the newly added decision.
  }

  const hasChanges = useMemo(() => {
    if (
      change.decisionTexts.userAdded.length > 0 ||
      change.decisionTexts.generated.length > 0
    ) {
      return true
    }
    return false
  }, [change])

  const onRemoveDecision = (decisionId) => {
    const newDelivery = removeDecision(decisionId, delivery, change)
    onChange({ deliveryId, data: newDelivery })
  }

  return (
    <DecisionContainer>
      <DecisionHeader hasChanges={hasChanges}>
        <FlexRow>
          <DetailBox>
            <Title>{getTitle(change, t)}</Title>
          </DetailBox>
          <AlignLeft>
            <>
              {change.changeData?.length > 0 && (
                <>
                  <GenerateDecisionButton
                    padding={false}
                    onClick={generateDecisions}
                  >
                    {t("generate-decision-text-button-text")}
                  </GenerateDecisionButton>
                </>
              )}
            </>
          </AlignLeft>
        </FlexRow>
      </DecisionHeader>
      <DecisionBody>
        {change.decisionTexts.generated.map((decision) =>
          decision.allowModification ? (
            <Spacing>
              <CreateDecisionText
                t={t}
                onCancelEdit={() => onRemoveDecision(decision.id)}
                onAddDecision={onAddDecision}
                decision={decision}
              />
            </Spacing>
          ) : (
            <DecisionText
              t={t}
              decision={decision}
              change={change}
              delivery={delivery}
              deliveryId={deliveryId}
              onChange={onChange}
              key={decision.id}
              isGenerated={true}
            />
          )
        )}
        {change.decisionTexts.userAdded.map((decision) => (
          <DecisionText
            t={t}
            decision={decision}
            change={change}
            delivery={delivery}
            deliveryId={deliveryId}
            onChange={onChange}
            key={decision.id}
          />
        ))}
      </DecisionBody>
      <DecisionFooter>
        {manualAdd ? (
          <div>
            <CreateDecisionText
              t={t}
              onCancelEdit={() => setManualAdd(false)}
              onAddDecision={onAddDecision}
            />
          </div>
        ) : (
          <AddDecisionButton onClick={() => setManualAdd(!manualAdd)}>
            {t("add-text-manually")}
          </AddDecisionButton>
        )}
      </DecisionFooter>
    </DecisionContainer>
  )
}

const Spacing = styled.div`
  padding: 14px 0;
`

const GenerateDecisionButton = styled.button`
  color: ${Colors.Sea}
  background-color: inherit;
  border: none;
  padding: 0 14px;
  cursor: pointer;
  border-left: 1px solid #e5edff;
  height: 100%
`
const AddDecisionButton = styled.button`
  background-color: inherit;
  border: 2px solid #c7d8ff;
  border-radius: 4px;
  padding: 4px 14px;
  color: #467eff;
  cursor: pointer;

  &:hover {
    background-color: #ecf2ff;
    border: 2px solid #c7d8ff;
  }
`

const AlignLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  height: 100%;
`

export const DecisionContainer = styled.div`
  box-sizing: border-box;
  background-color: #f5f8ff;
  border: 1px solid #e5edff;
  border-radius: 10px;
  overflow: hidden;
  &:not(:first-child) {
    margin-top: 20px;
  }
`

export const DecisionHeader = styled.div`
  height: 46px;
  padding: 0 14px;
  border-bottom: ${({ hasChanges }) =>
    hasChanges ? "1px solid #e5edff;" : "none"};
`

export const DecisionBody = styled.div`
  padding: 0 14px;
  background-color: white;
`

const DecisionFooter = styled.div`
  background-color: white;
  padding: 14px;
  border-top: 1px solid #e5edff;
`

const Title = styled.h3`
  color: black;
  font-weight: 400;
`

const DetailBox = styled.div`
  margin: 10px 0px;
`

export default Change
