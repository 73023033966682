/**
 * @param children component to toggle
 * @returns {null|*} return null if not environment === dev, else return children
 */
// OBS: environment only exists in dev IF you add it manually. This can NOT be used to check for other environments, for instance prod
const ONLY_RENDER_IN_DEVELOPMENT = ({ children }) => {
  if (localStorage.getItem("environment") !== "dev") {
    return null
  }
  return children
}

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

const getNamespace = () => {
  if (isLocalhost) return "dev"
  return window.location.hostname.split(".")[1]
}

/**
 * @returns {boolean} true if environment === dev, false if not
 */
const ENVIRONMENT_SET_TO_DEVELOPMENT = () =>
  localStorage.getItem("environment") === "dev"

const FLOW_NS_DEV_OR_TEST = () => {
  const ns = getNamespace()
  return ns.includes("test") || ns.includes("dev") || ns.includes("insight")
}

const FLOW_NS_DEV_OR_TEST_OR_STAGING = () => {
  const ns = getNamespace()
  return (
    ns.includes("test") ||
    ns.includes("dev") ||
    ns.includes("insight") ||
    ns.includes("staging") ||
    ns.includes("release")
  )
}

const FLOW_NS_STAGING = () => {
  const ns = getNamespace()
  return ns.includes("staging")
}

const FLOWNS_IS_DEV_ENV_VAR_IS_DEV = () =>
  FLOW_NS_DEV_OR_TEST() && ENVIRONMENT_SET_TO_DEVELOPMENT()

const FEATURE_TOGGLE_MATIL_PRODUCT = (product) => {
  const featureToggledProductTypes = ["matil", "matilRegional"]
  if (ENVIRONMENT_SET_TO_DEVELOPMENT()) return true
  else {
    if (featureToggledProductTypes.includes(product)) return false
  }
  return true
}

export {
  ONLY_RENDER_IN_DEVELOPMENT,
  ENVIRONMENT_SET_TO_DEVELOPMENT,
  FEATURE_TOGGLE_MATIL_PRODUCT,
  FLOWNS_IS_DEV_ENV_VAR_IS_DEV,
  FLOW_NS_DEV_OR_TEST,
  FLOW_NS_DEV_OR_TEST_OR_STAGING,
  FLOW_NS_STAGING,
}
