import React, { useState } from "react"
import styled from "styled-components"
import { Text, TextArea, Checkbox } from "@flow/forms"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { number, object, string } from "yup"
import { validateSchema } from "../../util/validateYupSchema"
import ErrorText from "../common/ErrorText"
import { Input } from "@staccx/bento"
import { Colors } from "@flow/style"

//const phoneRegExpNorskeTelefonnummer = /^(0047|\+47|47)?[2-9]\d{7}$/
const guarantorSchema = object({
  ssn: string()
    .length(11, "Personnummer må være 11 siffer")
    .required("Påkrevd"),
  email: string().email("Må være en gyldig email!").required("Påkrevd"),
  mobile: string()
    //.matches(phoneRegExpNorskeTelefonnummer, "Telefonnummer er ikke gyldig")
    .required("Påkrevd"),
  amount: number().typeError("Må være et nummer!"),
  organizationNumber: string()
    .length(9, "Organisasjonsnummer må være 9 siffer")
    .required("Påkrevd"),
  contactPersonName: string().required("Påkrevd"),
  contactPersonEmail: string()
    .email("Må være en gyldig email!")
    .required("Påkrevd"),
  contactPersonMobile: string().required("Påkrevd"),
  organizationCountry: string().required("Påkrevd"),
  name: string().required("Påkrevd"),
})

const CurrencyInput = ({ value, t, onChange }) => {
  return (
    <InputStyled
      name="denomination-in-nok"
      id="denomination-in-nok"
      mode="currency"
      inputMode="numeric"
      pattern={/[0-9]*/}
      type="text"
      label={t("denomination-in-nok")}
      value={value || "0"}
      onChange={(e) => onChange({ target: { value: e.rawValue } })}
    />
  )
}
const EditGuarantor = ({
  schema,
  key,
  guarantor,
  t,
  onChange,
  onCancel,
  onSave,
}) => {
  let fieldKey = guarantor.type
  if (guarantor.type === "organization")
    fieldKey =
      guarantor.scope === "international"
        ? "organizationInternational"
        : "organizationDomestic"

  const fields = schema[fieldKey]

  const [errors, setErrors] = useState({})

  const updateValue = (key, value) => {
    const updatedGuarantor = { ...guarantor }
    updatedGuarantor[key] = value
    onChange(updatedGuarantor)
  }

  const validate = async (guarantor) => {
    const result = await validateSchema(guarantorSchema, guarantor)

    const fieldsHasErrors = fields.some((x) =>
      Object.keys(result?.errors || {}).includes(x.key)
    )
    setErrors(result.errors)

    return !fieldsHasErrors
  }

  const save = async () => {
    if (!(await validate(guarantor))) return

    onSave(guarantor)
  }

  return (
    <Content>
      <FormContainer>
        {fields.map((x, i) => {
          if (x.type === "label") {
            return (
              <>
                <div style={{ flexBasis: "100%", height: 0 }}></div>
                <div>{t(x.label)}</div>
              </>
            )
          }

          if (x.type === "perform-credit-check") {
            return (
              <>
                <div style={{ flexBasis: "100%", height: 0 }}></div>
                <div className="credit-check">
                  <Checkbox
                    id={`new-credit-check-${key}`}
                    name={`new-credit-check-${key}`}
                    label={t("fetch-updated-credit")}
                    checked={guarantor?.creditCheck}
                    onChange={(e, val) =>
                      updateValue("creditCheck", !guarantor?.creditCheck)
                    }
                  />
                </div>
              </>
            )
          }
          let FormElement = Text
          if (x.type === "textarea") {
            FormElement = StyledTextArea
          } else if (x.type === "currency") {
            FormElement = CurrencyInput
          }
          return (
            <InputField>
              <ErrorText error={errors[x.key]}>
                <FormElement
                  key={`${x.key}-${i}`}
                  name={`${x.key}-${i}`}
                  label={t(x.label)}
                  value={guarantor[x.key]}
                  onChange={(e) => updateValue(x.key, e.target.value)}
                  t={t}
                />
              </ErrorText>
            </InputField>
          )
        })}
      </FormContainer>
      <ButtonContainer>
        <PrimaryButton onClick={() => save()}>{t("Legg til")}</PrimaryButton>
        <SecondaryButton type="button" onClick={() => onCancel()}>
          {t("cancel")}
        </SecondaryButton>
      </ButtonContainer>
    </Content>
  )
}

const FormContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
`

const InputStyled = styled(Input)`
  input {
    border: solid 1px ${Colors.Grey2};
    border-radius: 0.4em;
    padding: 0.5em;
    line-height: 1.4em;
    font-size: 14px;
    width: calc(100% - 1em);
  }

  label {
    font-size: 12px;
    line-height: 1.4em;
    letter-spacing: 0.01em;
    padding: 0.2em;
  }
`

const InputField = styled.div`
  width: calc(40%);
  margin-right: 50px;
  margin-bottom: 10px;
`

const Content = styled.div``
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`
const StyledTextArea = styled(TextArea)`
  width: calc(200% + 50px);
`
export default EditGuarantor
