import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import Safety from "./Safety"

const SafetyContainer = ({ t }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      control={control}
      name={"safety"}
      key={"safety"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <Safety
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          t={t}
        />
      )}
    />
  )
}

export default SafetyContainer
