import React, { useEffect, useMemo } from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"
import { useFormContext, useFieldArray } from "react-hook-form"
import { calculatePercentageOf } from "../../../util/calculatePercentageOf"
import Collapsible from "../../common/Collapsible"
import MainProductLine from "./MainProductLine"
import SubFieldsLine from "./SubFieldsLine"
import { useAgreement } from "./useProductDraft"
import AgreementFieldsLine from "./AgreementFieldsLine"
import {
  shouldValidateProducts,
  validateAndShowInvalidProducts,
} from "../mapCaseInput"
import GuaranteeFieldsLine from "./GuaranteeFieldsLine"

// If application is within dpv domain, show all products
// If application is not within dpv domain, do not show products marked with isDpv: true
const isAllowedProductType = (applicationWithinDpv, productIsDpv) => {
  if (applicationWithinDpv) return true
  else {
    return !productIsDpv
  }
}

const ProductLine = ({
  product,
  amount,
  fundingIntensity,
  productType,
  index,
  t,
  options,
  onDelete,
  inputTotal,
  refreshValues,
  supportRegime,
  isDynamicCostBased = false,
}) => {
  const { fields, append, remove } = useFieldArray({
    mode: "onChange",
    name: `products.${index}.subproducts`,
  })

  const { setValue } = useFormContext()
  const { isDpv: applicationWithinDpv, productConfiguration } = options || {}

  const currentProduct = productConfiguration[product]
  const [agreement, setAgreement, resetAgreement] = useAgreement(currentProduct)

  /**
   * @typedef {object} DropdownValue
   * @property {string} value - The value of the dropdown option
   * @property {string} name - The name of the dropdown option
   * @property {boolean} isValid - Whether the product is valid or not for given support regime
   */

  /**
   * @type {DropdownValue[]}
   * @returns {DropdownValue[]} - Returns the dropdown values
   *
   */
  const dropdownValues = useMemo(() => {
    // We need to check all products if there exists any eosArticles at all
    // If not, we need to show the product in the dropdown and skip the validation
    const hasEosArticles = Object.values(productConfiguration).some(
      ({ eosArticles }) => eosArticles?.length > 0
    )
    const productsNeedValidation = shouldValidateProducts(
      hasEosArticles,
      isDynamicCostBased
    )

    return Object.keys(productConfiguration)?.reduce((acc, curr) => {
      const {
        loanPurposeCode,
        displayNameShort,
        marketingName,
        isDpv: productIsDpv,
      } = productConfiguration[curr]
      if (isAllowedProductType(applicationWithinDpv, productIsDpv)) {
        const products = [{ ...productConfiguration[curr], product: curr }]
        const activities = [
          {
            products,
            supportRegime,
          },
        ]
        // Used to show in dropdown if product is valid or not
        const invalidProducts = validateAndShowInvalidProducts(
          activities,
          productConfiguration
        )
        const isValid = !invalidProducts || invalidProducts.length === 0
        acc.push({
          value: curr,
          name: `${loanPurposeCode} ${displayNameShort} ${marketingName}`,
          isValid: productsNeedValidation ? isValid : true,
        })
      }
      return acc
    }, [])
  }, [productConfiguration, applicationWithinDpv, supportRegime])

  useEffect(() => {
    const intensity = calculatePercentageOf(amount, inputTotal)
    setValue(`products.${index}.fundingIntensity`, intensity)
  }, [inputTotal, setValue, index])

  const changeAmount = async (value, index) => {
    amount = value
    const intensity = calculatePercentageOf(value, inputTotal)
    setValue(`products.${index}.fundingIntensity`, intensity)
    setValue(`products.${index}.amount`, value.toString())
    await refreshValues()
  }

  const onIntensityChanged = async (value, index) => {
    amount = Math.floor(((value / 100) * inputTotal).toFixed(2)).toString()
    setValue(`products.${index}.amount`, amount)
    //await refreshValues()
  }

  const handleOnChangeAmount = async (value, onChange, index) => {
    onChange(value)
    await changeAmount(value, index)
  }

  const handleOnChangeProduct = async (value, onChange, index) => {
    const newProduct = productConfiguration[value]
    const productType = newProduct?.productType || null

    onChange(value)
    setValue(`products.${index}.productType`, productType)

    if (
      newProduct?.amountLowerLimit === newProduct?.amountUpperLimit &&
      newProduct?.amountLowerLimit !== 0 &&
      newProduct?.amountLowerLimit != null
    ) {
      const newValue = newProduct.amountUpperLimit
      await changeAmount(newValue, index)
    } else {
      await changeAmount(0, index)
    }

    // reset fields
    fields.forEach((field) => remove(field))
    // append relevant fields
    options?.productConfiguration[value]?.subproducts?.forEach((sub) => {
      append({ ...sub, amount: "0" })
    })
  }

  const isGuarantee = currentProduct?.productClass === "Guarantee"

  if (
    fields.length === 0 &&
    (!agreement || agreement?.isLoading === false) &&
    (agreement?.customFields?.length ?? 0) === 0 &&
    !isGuarantee
  ) {
    return (
      <ProductWrapper>
        <MainProductLine
          index={index}
          paddingLeft={35}
          currentProduct={currentProduct}
          handleOnChangeProduct={handleOnChangeProduct}
          handleOnChangeAmount={handleOnChangeAmount}
          dropdownValues={dropdownValues}
          fundingIntensity={fundingIntensity}
          productType={productType}
          onDelete={onDelete}
          onIntensityChanged={onIntensityChanged}
          t={t}
          supportRegime={supportRegime}
        />
      </ProductWrapper>
    )
  }

  const handleDeletion = (args) => {
    // Reset agreement data to default values
    resetAgreement(currentProduct.productId, currentProduct.productClass)

    // Delete product
    onDelete(args)
  }

  return (
    <ProductWrapper>
      <StyledCollapsible
        color={Colors.Sea}
        isOpen={true}
        isLoading={agreement?.isLoading}
        iconSize={25}
        header={
          <MainProductLine
            index={index}
            currentProduct={currentProduct}
            handleOnChangeProduct={handleOnChangeProduct}
            handleOnChangeAmount={handleOnChangeAmount}
            dropdownValues={dropdownValues}
            fundingIntensity={fundingIntensity}
            productType={productType}
            onDelete={handleDeletion}
            supportRegime={supportRegime}
            onIntensityChanged={onIntensityChanged}
            t={t}
          />
        }
      >
        <CollapsibleContent>
          {isGuarantee && (
            <GuaranteeFieldsLine
              t={t}
              index={index}
              currentProduct={currentProduct}
            />
          )}
          <SubFieldsLine
            index={index}
            name="subproducts"
            fieldName="amount"
            fields={fields}
            t={t}
          />
          <AgreementFieldsLine
            index={index}
            name="customFields"
            fieldName="value"
            fields={agreement?.customFields || []}
            t={t}
          />
        </CollapsibleContent>
      </StyledCollapsible>
    </ProductWrapper>
  )
}

export default ProductLine

const ProductWrapper = styled.div`
  padding-right: ${(props) => props.paddingRight};
  background-color: ${Colors.Grey4};
  margin-top: 10px;
  border-radius: 5px;
`

const StyledCollapsible = styled(Collapsible)`
  background-color: ${Colors.Grey4};
  border-radius: 5px;
  border: 1px solid rgb(239, 239, 239);
`

const CollapsibleContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px 10px 15px 50px;
  background-color: white;
  border-top: 1px solid rgb(239, 239, 239);
`
