import React from "react"
import { SecondaryButton } from "@flow/buttons"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import axios from "axios"

const OpenApplicationButton = ({ state }) => {
  const { t } = useTranslation()
  const storeId = state?.application?.attachments
    ?.filter((x) => x.attachmentName === "Preview_Application.pdf")
    ?.at(0)?.attachmentId
  const goToApplication = async () => {
    try {
      const response = await axios.get(`/api/files/${storeId}`, {
        responseType: "blob",
      })

      const file = new Blob([response.data], { type: "application/pdf" })
      const fileURL = URL.createObjectURL(file)
      const pdfWindow = window.open()
      pdfWindow.location.href = fileURL
    } catch (error) {
      console.error(error)
      return { error }
    }
  }
  return (
    <ApplicationBtnDiv>
      {storeId && (
        <SecondaryButton onClick={() => goToApplication()}>
          {t("go-to-application")}
        </SecondaryButton>
      )}
    </ApplicationBtnDiv>
  )
}

const ApplicationBtnDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 0 1.9em;
`

export default OpenApplicationButton
