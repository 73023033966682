import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import React, { useState } from "react"
import Heading from "../components/common/Heading"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { Colors } from "@flow/style"
import { Tabs } from "@flow/components"
import DownloadFileButton from "../components/common/DownloadFileButton"
import dateFormatter from "../util/dateFormatter"
import { MiniTable } from "../components/controlPriority/TableForCheckList"
import { FormProvider, useForm } from "react-hook-form"
import InsightModule from "../components/insight/InsightModule"
import {
  getNewAndExistingEngagements,
  mapLoansWithAgreementData,
} from "../components/utils/mapAgreements"

const RegisterCollateralPropertyManual = ({
  task,
  t,
  flow,
  schema,
  save,
  complete,
  user,
}) => {
  const { variables } = task
  const [taskData, setTaskData] = useState(task.data || task.default)
  const [isProcessing, setProcessing] = useState(false)
  const [activeTab, setActiveTab] = useState("registerCollateralManual")
  const delivery = flow?.data?.deliveries[variables.delivery.id]
  const currentAgreementId = variables?.collateralElement
  const tinglysning =
    delivery?.collateral?.servicesAmbitaEtinglysing[currentAgreementId]
      ?.registrationStatus

  const defaultValue = {
    [currentAgreementId]: {
      checklistComment: taskData?.checklistComment ?? "",
      checklistControlled: taskData?.checklistControlled ?? false,
      checklistControlledBy: taskData?.checklistControlledBy ?? "",
    },
  }

  const engagements = flow.data.mapped.engagement.loans.map((loan) => {
    return {
      ...loan,
      riskGroupCode:
        task.data?.loans?.find(
          (taskLoan) => loan.accountNumber === taskLoan.accountNumber
        )?.riskGroupCode ?? loan.riskGroupCode,
    }
  })
  const securityRiskData =
    flow.data.analysis?.termsAndSecurity?.input?.securityRiskData ?? []

  const newAndExistingCollateralEngagements = getNewAndExistingEngagements(
    flow.data.deliveries,
    flow.data.mapped?.collateralEngagements || []
  )

  const isMaintenance = flow.data.deliveries.vedlikehold !== undefined

  const mappedLoansWithCollateralAgreements = mapLoansWithAgreementData(
    flow.data.mapped.agreementData || [],
    newAndExistingCollateralEngagements,
    engagements,
    securityRiskData,
    isMaintenance
      ? flow.data.deliveries.vedlikehold?.changes
          ?.map((change) => change.changeData)
          .flat() ?? []
      : []
  )

  const methods = useForm({
    defaultValues: defaultValue,
  })

  const currentUser = user?.profile?.name

  const handleSave = () => {
    const formData = methods.getValues()
    const newData = { ...taskData, ...formData[currentAgreementId] }
    setProcessing(true)
    save(
      newData,
      () => {},
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = () => {
    const formData = methods.getValues()
    const newData = { ...taskData, ...formData[currentAgreementId] }
    setProcessing(true)
    complete(
      newData,
      () => {},
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const relevantDocuments = flow?.data?.files?.filter(
    (x) =>
      x.filename === "Pantedokument i fast eiendom.pdf" &&
      x.documentGroup === "Signing"
  )

  const options = [
    {
      id: "registerCollateralManual",
      title: t("overview"),
      component: (
        <MainContainer>
          <FormProvider {...methods}>
            <form>
              <StyledWarningInfoBox>
                <p>{t("tinglysning-cancelled")}</p>
                <p>{t("tinglysining-cancelled-eiendom")}</p>
              </StyledWarningInfoBox>
              {tinglysning && (
                <StyledWarningInfoBox>
                  {tinglysning?.avslagsgrunn?.documentMessage?.map(
                    (documentMessage, i) => (
                      <p key={i}>{documentMessage?.detail}</p>
                    )
                  )}
                  <p>{dateFormatter(tinglysning?.tinglysingsdato)}</p>
                </StyledWarningInfoBox>
              )}
              <div>
                <Heading>{t("pant-documents")}</Heading>
                <Row>
                  {relevantDocuments
                    .filter((x) => !!x)
                    .map((x, i) => (
                      <DownloadFileButton
                        title={t(x?.filename)}
                        fileId={x?.storeID}
                        key={i}
                      />
                    ))}
                </Row>
              </div>
              <StyledMovables>
                <StyledTable>
                  <MiniTable
                    t={t}
                    agreementId={currentAgreementId}
                    currentUser={currentUser}
                  />
                </StyledTable>
              </StyledMovables>
            </form>
          </FormProvider>
        </MainContainer>
      ),
    },
    {
      id: "securities",
      title: t("Sikkerheter"),
      component: (
        <InsightComponentStyle>
          <InsightModule
            name={"@stacc/vue-corporateanalysis"}
            commonData={""}
            data={{
              agreementData: mappedLoansWithCollateralAgreements,
            }}
            options={{
              context: "agreementregistersummary",
            }}
          />
        </InsightComponentStyle>
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContent>
        <Tabs options={options} onChange={(e) => setActiveTab(e)} />
        <TabContainer>
          {options?.map((x, i) => (
            <div
              key={i}
              style={{ display: x?.id === activeTab ? "block" : "none" }}
            >
              {x?.component}
            </div>
          ))}
        </TabContainer>
      </MainContent>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={schema}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => setTaskData(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave(taskData)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  width: 100%;
  overflow-y: auto;
  gap: 1rem;
`

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`

const StyledWarningInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${Colors.OrangeLighter};
  padding: 1rem;
  border-radius: 10px;
`

const Row = styled.div`
  display: flex;
  gap: 15px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

const StyledMovables = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor || Colors.SeaLighter};
  border-radius: 15px;
  padding-top: 1em;
  margin-top: 1em;
`
const StyledTable = styled.div`
  display: flex;
  width: 100%;
`

const InsightComponentStyle = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
`

const TabContainer = styled.div`
  margin: 20px 20px 20px 0;
  flex: 1;
`
export default withTranslation()(RegisterCollateralPropertyManual)
