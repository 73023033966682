import styled from "styled-components"
import { Colors } from "@flow/style"
import { ProjectColors } from "../components/common/style/ProjectColors"

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

const getNamespace = () => {
  if (isLocalhost) return "dev"
  return window.location.hostname.split(".")[1]
}

const getAnimationForEnvironment = () => {
  const namespace = getNamespace()
  switch (namespace) {
    case "dev-arien": // 🦍
    case "dev-morten": // 😎
      return "16s linear infinite rainbow-animation"
    default:
      return "none"
  }
}

const getColorForEnvironment = () => {
  const namespace = getNamespace()
  switch (true) {
    case namespace.includes("dev"):
      return Colors.SeaDark
    case namespace.includes("hotfix"):
      return Colors.Grass
    case namespace.includes("stacc-test"):
    case namespace.includes("release"):
    case namespace.includes("ci"):
      return "rgb(255, 176, 0)"
    case namespace.includes("test"):
      return Colors.GrassDark
    case namespace.includes("insight"):
      return "rgb(100 15 170)"
    case namespace.includes("staging"):
      return Colors.FerrariDark
    default:
      return ProjectColors.MenuColor
  }
}
export default styled.div`
  // Physically accurate RGB values determined from https://academo.org/demos/wavelength-to-colour-relationship/
  @keyframes rainbow-animation {
    0% {
      // 780nm
      background-color: #610000;
    }
    10% {
      background-color: #b50000;
    }
    20% {
      // 700nm
      background-color: #ff0000;
    }
    30% {
      // 620nm
      background-color: #ff7700;
    }
    40% {
      background-color: #f0f000;
    }
    50% {
      // 540nm
      background-color: #81ff00;
    }
    60% {
      background-color: #00ff92;
    }
    70% {
      // 460nm
      background-color: #007bff;
    }
    80% {
      // 420nm
      background-color: #6a00ff;
    }
    90% {
      // 380nm
      background-color: #6a006a;
    }
    100% {
      // 780nm
      background-color: #610000;
    }
  }

  .mainHeader {
    animation: ${getAnimationForEnvironment()};
    background-color: ${getColorForEnvironment()};
  }

  .mainMenu {
    animation: ${getAnimationForEnvironment()};
    background-color: ${getColorForEnvironment()};
  }

  .mainMenuIcon.active {
    color: #030303;
    border-left: 3px solid #030303;
  }

  .activeElement {
    border-left: 2px solid #030303;
  }
`
