import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { Checkbox } from "@flow/forms"
import { FormProvider, useForm, Controller } from "react-hook-form"
import styled from "styled-components"
import validateEmail from "../../util/emailValidator"
import TextField from "../common/inputs/TextField"
import Signer from "./Signer"
import EditSigner from "./EditSigner"
import IconButton from "../common/IconButton"

const SelectedPerson = ({
  person,
  index,
  signRule,
  updateSelectedRule,
  isOther,
  t,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    register,
    onBlur,
    onChange,
    ...rest
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...person,
    },
  })

  person.email ??= ""

  const [showFields, setShowFields] = useState(person.selected || false)

  const updatePersonValidity = () => {
    person.isValid = validateEmail(person.email)
  }

  const handleChange = () => {
    person.selected = !showFields
    setShowFields(!showFields)
    updatePersonValidity()
    updateSelectedRule(signRule)
  }

  const deletePerson = (person) => {
    let rule = { ...signRule }
    rule.personRolleKombinasjon.splice(index, 1)
    updateSelectedRule(rule)
  }

  const onEdit = () => {
    if (!person.edit) {
      person.edit = {
        name: person.navn,
        ssn: person.ssn,
        email: person.email,
        position: person.rolle.tekstforklaring,
      }
    } else {
      person.edit = null
    }
    updateSelectedRule(signRule)
  }

  const isCustom = person.isCustom
  const showInput = (showFields || !isOther) && !person.edit

  const onEditSignerSubmit = (newPerson) => {
    delete person.edit
    const rule = { ...signRule }
    const mappedPerson = {
      navn: newPerson.name,
      ssn: newPerson.ssn,
      selected: person.selected,
      isCustom: true,
      email: newPerson.email,
      isValid: true,
      rolle: {
        tekstforklaring: newPerson.position,
      },
    }
    rule.personRolleKombinasjon[index] = mappedPerson

    updateSelectedRule(rule)
  }

  const onEditSignerChange = (newPerson) => {
    person.edit = newPerson

    updateSelectedRule(signRule)
  }
  const onEditSignerCancel = () => {
    delete person.edit
    updateSelectedRule(signRule)
  }

  const onFormChange = (value, type) => {
    person[type] = value || ""

    updatePersonValidity()
    updateSelectedRule(signRule)
  }

  return (
    <>
      {isOther && (
        <CheckboxContainer>
          <Checkbox
            name="personSelectedCheckbox"
            label={
              (person?.rolle?.tekstforklaring ?? "Ukjent rolle") +
              " - " +
              person.navn
            }
            onChange={handleChange}
            checked={person.selected}
            id={index.toString()}
          ></Checkbox>
          {isCustom && (
            <CheckboxOperations>
              <IconButton icon={"Edit"} onClick={() => onEdit()} />
              <IconButton
                icon={"Trashcan"}
                onClick={() => deletePerson(person)}
              />
            </CheckboxOperations>
          )}
        </CheckboxContainer>
      )}
      {isCustom && person.edit && (
        <EditSigner
          title={t("edit-signer")}
          person={person.edit}
          onSubmit={onEditSignerSubmit}
          onChange={onEditSignerChange}
          cancel={onEditSignerCancel}
          t={t}
        />
      )}
      {showInput && (
        <EditContainer>
          {!isOther && <Signer signer={person} isBold={true} />}

          <FormProvider
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            onChange={onChange}
            onBlur={onBlur}
            {...rest}
          >
            <form>
              <EmailField>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, validate: validateEmail }}
                  render={({
                    field: { name, value, onChange, onBlur, ...rest },
                  }) => (
                    <TextField
                      label={t("email")}
                      onChange={(value) => {
                        onFormChange(value, "email")
                        onChange(value)
                      }}
                      value={value}
                      field={{ ...rest, value, name, onChange, onBlur }}
                    />
                  )}
                />
                {errors?.email?.type === "validate" && (
                  <span>{t("invalid-email")}</span>
                )}
              </EmailField>
            </form>
          </FormProvider>
        </EditContainer>
      )}
    </>
  )
}

const EditContainer = styled.div`
  margin-bottom: 20px;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
  justify-content: space-between;
`

const CheckboxOperations = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`

const EmailField = styled.div`
  max-width: 50%;
`
export default withTranslation()(SelectedPerson)
