import React from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"
import { ProjectColors } from "../../common/style/ProjectColors"
import StyledInput from "../../common/inputs/StyledInput"
import { Controller } from "react-hook-form"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import { Input } from "@staccx/bento"
import { calculatePercentageOf } from "../../../util/calculatePercentageOf"
import TextButton from "../../common/TextButton"
import { Icons } from "../../common/style/Icons"

const ActivityLine = ({
  costType,
  projectCost,
  basisForSupport,
  participantCost,
  currency,
  header = false,
  isAdditionalProduct,
  isCustomActivity,
  amount = 0,
  index,
  refreshValues,
  name,
  readOnly = false,
  setValue,
  control,
  register,
  approved,
  editCost,
  isEditing,
  removeCosts,
  isDynamicCostBased,
  showParticipantsColumn = false,
}) => {
  const handleOnChange = (onChange, element) => {
    const fundingIntensity =
      element < 1 ? 0 : calculatePercentageOf(amount, element) || 0
    setValue("products.fundingIntensity", fundingIntensity)
    onChange(element)
  }
  const handling = async (onChange, element) => {
    setValue(`costs[${index}].approved`, element.toString())
    await refreshValues()
    onChange(element)
  }

  const isSumActivity = costType === "SUM ALLE AKTIVITETER"
  // Line 65 used to be id={key} but key is undefined (React doesn't pass key to child props)
  // so we're using name instead - maybe change this in the future

  return (
    <>
      {header ? (
        <ActivityLineItem
          header={true}
          isAdditionalProduct={isAdditionalProduct}
          showParticipantsColumn={showParticipantsColumn}
        >
          <StyledCostType isAdditionalProduct={isAdditionalProduct}>
            {costType}
          </StyledCostType>

          <StyledCosts isAdditionalProduct={isAdditionalProduct}>
            {`${projectCost} ${isSumActivity ? currency : ""}`}
          </StyledCosts>
          <InputGrid isAdditionalProduct={isAdditionalProduct}>
            {`${basisForSupport} ${isSumActivity ? currency : ""}`}
          </InputGrid>
          {showParticipantsColumn && (
            <StyledCostType>
              {`${participantCost} ${isSumActivity ? currency : ""}`}
            </StyledCostType>
          )}
        </ActivityLineItem>
      ) : (
        <ActivityLineItem
          isAdditionalProduct={isAdditionalProduct}
          showParticipantsColumn={showParticipantsColumn}
        >
          {/* KOSTNADSTYPE */}
          {isEditing ? (
            <StyledInput customWidth="350px" maxWidth="350px">
              <input
                name={costType}
                id={costType}
                defaultValue={costType}
                type="text"
                onChange={(e) => editCost(index, "name", e.target.value)}
              />
            </StyledInput>
          ) : (
            <StyledCostType isAdditionalProduct={isAdditionalProduct}>
              {costType}
            </StyledCostType>
          )}

          {/* PROSJEKTKOSTNADER */}
          <StyledCosts isAdditionalProduct={isAdditionalProduct}>
            {/* Note: This won't work on additional product pt, but is not necessary pt. */}
            {!isAdditionalProduct && !readOnly && (
              <StyledInput>
                <Controller
                  control={control}
                  name={`costs.${index}.appliedFor`}
                  key={`costs.${index}.appliedFor`}
                  render={({
                    field: { name, key, onChange, onBlur, ...rest },
                  }) => (
                    <Input
                      name={name}
                      id={name}
                      key={key}
                      mode="currency"
                      inputMode="numeric"
                      pattern={/[0-9]*/}
                      type="text"
                      onChange={(e) => onChange(e.rawValue || "0")}
                      onBlur={(e) => onBlur(e.rawValue || "0")}
                      ref={register}
                      {...rest}
                    />
                  )}
                />
              </StyledInput>
            )}
            {readOnly && (
              <p>{`${addThousandSeparator(projectCost)} ${currency}`}</p>
            )}
          </StyledCosts>

          {/* STØTTEGRUNNLAG */}
          {!readOnly && (
            <InputGrid isAdditionalProduct={isAdditionalProduct}>
              <StyledInput>
                <Controller
                  control={control}
                  name={isAdditionalProduct ? name : `costs.${index}.approved`}
                  render={({
                    field: { name, key, onChange, onBlur, ...rest },
                  }) => (
                    <Input
                      name={name}
                      id={name}
                      key={name}
                      mode="currency"
                      inputMode="numeric"
                      pattern={/[0-9]*/}
                      type="text"
                      onChange={(e) =>
                        isAdditionalProduct
                          ? handleOnChange(onChange, e.rawValue || "0")
                          : handling(onChange, e.rawValue || "0")
                      }
                      onBlur={(e) => onBlur(e.rawValue)}
                      ref={register}
                      {...rest}
                    />
                  )}
                />
              </StyledInput>
            </InputGrid>
          )}
          {!isCustomActivity && readOnly && (
            <p>{`${addThousandSeparator(approved)} ${currency}`}</p>
          )}

          {/* PROSJEKTDELTAKERS KOSTNADER */}
          {showParticipantsColumn && !readOnly && (
            <StyledCosts isAdditionalProduct={isAdditionalProduct}>
              <StyledInput>
                <Controller
                  control={control}
                  name={`costs.${index}.participantCost`}
                  key={`costs.${index}.participantCost`}
                  render={({
                    field: { name, key, onChange, onBlur, ...rest },
                  }) => (
                    <Input
                      name={name}
                      id={name}
                      key={key}
                      mode="currency"
                      inputMode="numeric"
                      pattern={/[0-9]*/}
                      type="text"
                      onChange={(e) => onChange(e.rawValue || "0")}
                      onBlur={(e) => onBlur(e.rawValue || "0")}
                      ref={register}
                      {...rest}
                      value={rest?.value || "0"}
                    />
                  )}
                />
              </StyledInput>
            </StyledCosts>
          )}
          {showParticipantsColumn && readOnly && (
            <p>{`${addThousandSeparator(participantCost)} ${currency}`}</p>
          )}

          {isEditing && (
            <StyledButton>
              <TextButton
                onClick={() => removeCosts(index)}
                padding={false}
                paddingConfiguration={undefined}
              >
                <Icons.Trashcan />
              </TextButton>
            </StyledButton>
          )}
        </ActivityLineItem>
      )}
    </>
  )
}
export default ActivityLine

const StyledCosts = styled.div`
  grid-column: ${({ isAdditionalProduct }) => isAdditionalProduct && "3"};

  display: flex;
  flex-direction: row;

  p {
    :first-child {
      padding-right: ${({ isAdditionalProduct }) =>
        isAdditionalProduct ? "0px" : "3px"};
    }
  }
`

const StyledCostType = styled.p`
  grid-column: ${({ isAdditionalProduct }) => (isAdditionalProduct ? "1" : "")};
`

const InputGrid = styled.div`
  grid-column: ${({ isAdditionalProduct }) => (isAdditionalProduct ? "4" : "")};

  div {
    display: ${({ isAdditionalProduct }) => isAdditionalProduct && "flex"};
    justify-content: ${({ isAdditionalProduct }) =>
      isAdditionalProduct && "flex-start"};
  }
`

const ActivityLineItem = styled.li`
  p:first-letter {
    text-transform: capitalize;
  }

  p {
    max-width: 350px;
    overflow-wrap: break-word;
  }

  border-radius: 5px;
  padding: 10px;
  margin: 4px;
  display: grid;
  background-color: ${(props) =>
    props.header ? `${Colors.Snow}` : `${ProjectColors.Slate95}`};
  grid-template-columns: ${({ isAdditionalProduct, showParticipantsColumn }) =>
    isAdditionalProduct
      ? "1fr 2fr 1fr 1fr 0.1fr"
      : showParticipantsColumn
      ? "2fr 1fr 1fr 1fr 0.1fr"
      : "3fr 1.5fr 1fr 0.1fr"};

  :last-child {
    p:last-child {
      padding-left: 4px;
    }
  }
`
const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: inherit;
  cursor: pointer;
`
