import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { Colors, FontStyles } from "@flow/style"
import axios from "axios"
import { PrimaryButton } from "@flow/buttons"

const PER_PAGE = 20

const getAuditLogs = async (setData, search, page, perPage = 20) => {
  try {
    const res = await axios.get(
      `/api/auditlogs?perPage=${perPage}&currentPage=${page}&search=${search}`
    )
    const auditLogChronological = res.data
    auditLogChronological.forEach((el) => {
      delete el.id
    })
    setData(auditLogChronological)
  } catch (error) {
    console.error("Failed to fetch data from services-auditlog.", error)
  }
}

const AuditLog = (props) => {
  const [auditLogData, setAuditLogData] = useState(null)
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const { t } = props

  useEffect(() => {
    getAuditLogs(setAuditLogData, search, page, PER_PAGE)
  }, [search, page])

  const keys = [
    ...new Set(
      auditLogData?.reduce((objectKeys, currentKey) => {
        objectKeys.push(...Object.keys(currentKey))
        return objectKeys
      }, [])
    ),
  ]

  const generateTableHeaders = () => {
    return (
      <tr key={0}>
        {keys.map((cell) => {
          return (
            <th
              key={cell}
              style={{ textTransform: "capitalize", fontWeight: "500" }}
            >
              {t(cell)}
            </th>
          )
        })}
      </tr>
    )
  }

  const generateTableRows = () => {
    const regex = new RegExp(`.*${search}`, "i")
    const tableRows = []
    let key = 0

    auditLogData
      ?.filter((record) => {
        let searchHit = ""

        keys.forEach((attribute) => {
          if (record?.hasOwnProperty(attribute)) {
            if (typeof record[attribute] === "string") {
              if (record[attribute]?.match(regex)) {
                searchHit = record[attribute].match(regex)
              }
            }
          }

          if (attribute === "role") {
            // Makes 'role' array searchable by converting it to string
            record[attribute] = record[attribute].toString()
            if (record[attribute]?.match(regex)) {
              searchHit = record[attribute].match(regex)
            }
          }

          if (attribute === "time") {
            // Make date value searchable by converting it string
            const date = new Date(record[attribute]).toLocaleDateString("en-GB")
            if (date?.match(regex)) {
              searchHit = date.match(regex)
            }
          }
        })

        return searchHit
      })
      ?.forEach((record) => {
        // Generate a table row for each search hit
        const keys = Object.keys(record)
        tableRows?.push(
          <tr key={key}>
            {keys.map((cell) => {
              if (keys?.includes(cell)) {
                let element = record[cell]
                if (Array?.isArray(element)) element = element?.join(", ")
                if (typeof element === "object")
                  element = JSON?.stringify(element)
                if (cell === "time")
                  element = new Date(element)?.toLocaleDateString("en-GB")
                return <td key={cell}>{t(element)}</td>
              }
              return <td key={cell}>{}</td>
            })}
          </tr>
        )
        key++
      })
    return tableRows
  }

  const getEmptyTable = (tableRows) => {
    const truncate = (str, maxLength) => {
      // Helper function for setting max length on empty search message.
      return str.length > maxLength ? str.substr(0, maxLength - 1) + "..." : str
    }

    return (
      <EmptySearchMessage>
        {t("empty-search-msg1")} <b>{truncate(search, 69)}</b>.
        <ul>
          <li>{t("empty-search-msg2")}.</li>
          <li>{t("empty-search-msg3")}.</li>
          <li>{t("empty-search-msg4")}.</li>
        </ul>
      </EmptySearchMessage>
    )
  }

  // Only render the table if data is finished loading.
  if (!auditLogData)
    return (
      <p style={{ display: "flow-root", padding: "15vh" }}>{t("loading")}</p>
    )
  const tableRows = generateTableRows()

  const paginate = (page) => setPage(page)
  return (
    <LandingFrame>
      <FrameContent>
        <SearchContent>
          <InputField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("default-inputtext-auditlog")}
          />
        </SearchContent>

        {tableRows.length > 0 && tableRows.length !== auditLogData.length && (
          <SearchResultMetaInfo>
            {t("search-hits")}: {tableRows.length}{" "}
            <span style={{ color: Colors.Grey2 }}>
              av {auditLogData.length} rader totalt
            </span>
          </SearchResultMetaInfo>
        )}

        <StyledTable>
          {tableRows.length < 1 ? (
            <>{getEmptyTable(tableRows)}</>
          ) : (
            <>
              <thead style={{ width: "1406px" }}>
                {generateTableHeaders()}
              </thead>
              <tbody>{tableRows}</tbody>
            </>
          )}
        </StyledTable>
        <StyledFooter>
          <StyledButtonGroup>
            <PrimaryButton onClick={() => paginate(1)}>
              {t("first-page")}
            </PrimaryButton>
            <PrimaryButton
              disabled={page <= 1}
              onClick={() => paginate(page - 1)}
            >
              {t("previous-page")}
            </PrimaryButton>
          </StyledButtonGroup>
          <FooterText>Side {page}</FooterText>
          <PrimaryButton
            disabled={tableRows.length < PER_PAGE}
            onClick={() => paginate(page + 1)}
          >
            {t("next-page")}
          </PrimaryButton>
        </StyledFooter>
      </FrameContent>
    </LandingFrame>
  )
}
const FooterText = styled.p`
  ${FontStyles.Large};
`
const StyledFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
`

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`

const StyledTable = styled.table`
  overflow: scroll;
  font-family: "IBM Plex Sans";
  border-spacing: unset;
  margin: 30px 0;
  font-size: 16px;
  min-width: 500px;
  max-height: 100px;
  table-layout: fixed;
  width: 92vw;

  th {
    white-space: nowrap;
    background-color: ${Colors.Coal};
    color: ${Colors.FerrariLightest};
    text-transform: capitalize;
  }

  td,
  th {
    text-align: left;
    padding: 1em 0.7em 1em 0.7em;
    font-size: 95%;
    word-wrap: break-word;
  }

  tbody tr:nth-of-type(even) {
    background-color: ${Colors.PrinceLightest};
  }
`
const FrameContent = styled.div`
  width: 92vw;
`
const SearchResultMetaInfo = styled.p`
  padding-top: 15px;
  padding-left: 10px;
`
const SearchContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const LandingFrame = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`
const InputField = styled.input`
  font-size: 20px;
  padding-top: 90px;
  flex-grow: 1;
  margin-left: 0;
  border: none;
  outline: none;
`
const EmptySearchMessage = styled.p`
  padding-left: 5vh;
`
AuditLog.propTypes = {
  t: PropTypes.func.isRequired,
}
export default withTranslation("Auditlog")(AuditLog)
