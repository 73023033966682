import React, { useState } from "react"
import Notice from "../common/Notice"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import { ProjectColors } from "../common/style/ProjectColors"
import Tooltip from "../common/ToolTip"
import { calculateRisk } from "../../util/calculatePercentageOf"

function findRiskCategory(number, ranges) {
  if (number < 0) return 1
  let exactMatch = ranges.find((range) => range.value === number.toString())
  if (exactMatch) return exactMatch.id

  for (let range of ranges) {
    if (range.value.includes("-")) {
      let [start, end] = range.value.split("-").map(Number)
      if (number >= start && number <= end) {
        return range.id
      }
    }
  }
  return null
}

const SecurityRiskAssessment = ({
  t,
  deliveries,
  securityRiskData,
  setSecurityRiskData,
  isMaintenance,
}) => {
  const ranges = [
    { id: "1", value: "0" },
    { id: "2", value: "1-10" },
    { id: "3", value: "11-25" },
    { id: "4", value: "26-50" },
    { id: "5", value: "51-75" },
    { id: "6", value: "76-100" },
  ]

  const SECURITY_RISK_ASSESSMENT_LINK =
    "https://innovationnorway.sharepoint.com/sites/Finansieringshandboken#4.16-sikkerheter"

  const handleChange = (event, index, delivery) => {
    const nextValue = event.target.value.replace(/[^\d.]/g, "")
    const security = parseInt(nextValue)
    const risk = calculateRisk(delivery.amount, security)
    const safetyRisk = findRiskCategory(risk, ranges)
    const { deliveryId } = securityRiskData[index]
    const tempData = [...securityRiskData]
    tempData[index] = {
      deliveryId,
      safetyRisk,
      safetyRiskPercentage: risk,
      securityAmount: security,
    }
    setSecurityRiskData(tempData)
  }

  return (
    <>
      <StyledLinkWrapper>
        <StyledLink href={SECURITY_RISK_ASSESSMENT_LINK}>
          {t("financialGuideLink")}
        </StyledLink>
      </StyledLinkWrapper>
      {securityRiskData?.map(
        ({ securityAmount, deliveryId, safetyRiskPercentage: risk }, index) => {
          const delivery = deliveries[deliveryId]

          const deliveryAmount = addThousandSeparator(delivery.amount)
          return (
            <NoticeWrapper key={index}>
              <Notice
                backgroundColor={Colors.SeaLighter}
                border={{ size: "1px", style: "solid", color: Colors.Grey2 }}
              >
                {!isMaintenance && (
                  <DeliveryTitle>{`${delivery.loanPurposeCode}-${delivery.productName} kr ${deliveryAmount}`}</DeliveryTitle>
                )}

                <SecurityRiskAssessmentWrapper>
                  <RiskAssessment>
                    <RiskInputLabel htmlFor="quantity">
                      {t(
                        "security-risk-label",
                        "Oppgi verdien på sikkerhetene for lånet"
                      )}
                      <TooltipWrapper>
                        <Tooltip
                          info={t(
                            "security-risk-tooltip",
                            `Sikkerhetsdekning viser til inndekning ved en tvangsrealisasjon av de stilte sikkerheter. 
                            Om lånet gis med garanti fra EIF skal denne inngå i beregningen`
                          )}
                        />
                      </TooltipWrapper>
                    </RiskInputLabel>
                    <InputWrapper>
                      <InputField
                        type="text"
                        id="quantity"
                        name="quantity"
                        placeholder="Beløp"
                        onChange={(event) =>
                          handleChange(event, index, delivery)
                        }
                        min="0"
                        max="1000000000000"
                        value={addThousandSeparator(securityAmount, true)}
                      ></InputField>{" "}
                      <RiskAssessmentCalculation>
                        <span>{t("risk", "Risiko") + ":"}</span>
                        <Risk>
                          {risk && risk >= 0 && risk < 100
                            ? `${risk}%`
                            : risk < 0
                            ? "0%"
                            : "100%"}{" "}
                        </Risk>
                      </RiskAssessmentCalculation>
                      <RiskAssessmentCalculation>
                        {t(
                          "security-risk-code-calculated",
                          "Sikkerhetskode (kalkulert)"
                        )}
                        :{" "}
                        <Risk>
                          {findRiskCategory(
                            calculateRisk(delivery.amount, securityAmount),
                            ranges
                          )}
                        </Risk>
                      </RiskAssessmentCalculation>
                    </InputWrapper>
                  </RiskAssessment>
                </SecurityRiskAssessmentWrapper>
              </Notice>
            </NoticeWrapper>
          )
        }
      )}
    </>
  )
}

const RiskInputLabel = styled.label`
  vertical-align: middle;
`

const SecurityRiskAssessmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 0.5em;
`

const NoticeWrapper = styled.div`
  width: 100%;
  padding: 10px 50px;
`

const DeliveryTitle = styled.p`
  ${FontStyles.Normal};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const InputField = styled.input`
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
    min-width: ${({ minWidth }) => minWidth};
    max-width: ${({ maxWidth }) => maxWidth};
    min-height: 30px;
    max-height: 40px;
    padding: 0.1em 0.4em 0.1em 0.4em;
    font-size: ${FontStyles.Normal};
    width: 10em;

    &::placeholder {
        color: ${Colors.Grey2};
    }
}
`
const RiskAssessment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`

const RiskAssessmentCalculation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
`

const Risk = styled.div`
  padding-left: 1em;
  font-weight: bold;
`

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding-left: 0.5em;
`
const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  margin-top: 10px;
  margin-bottom: 10px;
`
const StyledLink = styled.a`
  text-decoration: underline;
  color: ${ProjectColors.Blue};
  cursor: pointer;
`
export default SecurityRiskAssessment
