/**
 * Get the error message for the KAR payout
 * @param {string | null} paymentId
 * @param {InFlow.Delivery} delivery
 *
 * @returns {InFlow.KarCheckMessage}
 */
export const getKarErrorMessage = (delivery, paymentId) => {
  // If no paymentId we can fetch KAR-check from /deliveries/{deliveryId}/currentPayout/karCheck
  const karCheckByPaymentId =
    delivery?.currentPayout?.karCheck?.[paymentId]?.resultText

  // We know that a KAR-check has been performed in the beginning of the payment process, so if no KAR-check by paymentId, we can return the resultText
  if (!karCheckByPaymentId) {
    return delivery?.currentPayout?.karCheck.resultText
  }
  return karCheckByPaymentId
}
