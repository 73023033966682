import React, { useEffect, useState } from "react"
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form"
import styled from "styled-components"
import ActivityLine from "../lineItems/ActivityLine"
import LineList from "../LineList"
import ProductLine from "../lineItems/ProductLine"
import ProductLineHeader from "../lineItems/ProductLineHeader"
import TextButton from "../../common/TextButton"
import ActivityTypeLine from "../lineItems/ActivityTypeLine"
import Heading from "../../common/Heading"
import { ActivityDefaultValues } from "./Activity.validationSchema"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import InfoLine from "../InfoLine"
import TextField from "../../common/inputs/TextField"
import { getSupportRegimesFromProducts } from "../mapCaseInput"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"

import { Icons } from "@flow/icons"
import { Modal } from "@flow/modal"

const Activity = ({
  caseInput = {},
  currency = "NOK",
  t,
  index,
  handleBlur,
  getRecentValues,
  setGetRecentValues,
  options,
  category,
  isDynamicCostBased,
  showParticipantsColumn = false,
  canDelete,
  onDelete,
}) => {
  const [isModal, setModal] = useState(false)
  const costs = caseInput?.costs || []

  const { productConfiguration } = options || {}
  // If is dynamic costbased, use the support regimes from the products
  const mappedSupportRegimes = isDynamicCostBased
    ? getSupportRegimesFromProducts(productConfiguration)
    : []

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    register,
    onBlur,
    onChange,
    ...rest
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...ActivityDefaultValues,
      ...caseInput,
      costs,
      activityTitle: t(caseInput.activityTitle),
    },
  })

  const { fields, append, remove } = useFieldArray({
    mode: "onChange",
    control,
    name: "products",
  })

  const { fields: costFields } = useFieldArray({
    mode: "onChange",
    control,
    name: "costs",
  })

  //clearform necessary?
  useEffect(() => {
    if (getRecentValues) {
      const values = getValues()
      handleBlur(values)
    }
    setGetRecentValues(false)
  }, [getRecentValues])

  const products = watch("products")
  const watchedCosts = watch("costs")
  const activityType = watch("activityType")
  const supportRegime = watch("supportRegime")
  const activityStart = watch("activityStart")
  const activityEnd = watch("activityEnd")
  const isCustomActivity = watch("isCustomActivity")

  useEffect(() => {
    const values = getValues()
    handleBlur(values)
  }, [products, watchedCosts])

  // When supportRegime is changed, we need to update existing products
  // to make sure they are valid for the new support regime
  useEffect(() => {
    const values = getValues()
    const { products } = values
    const updatedProducts = products?.map((product) => {
      const { product: productName } = product
      const { displayName } = productConfiguration[productName] || {}
      return {
        ...product,
        product: productName,
        displayName,
      }
    })
    setValue("products", updatedProducts)
  }, [supportRegime])

  const addProduct = () => {
    append({
      product: "noOption",
      amount: "0",
      fundingIntensity: 0,
      productType: "not-chosen",
      subsidyEffect: 0,
      subproducts: [],
    })
  }

  const refreshValues = () => {
    const values = getValues()
    handleBlur(values)
  }

  const handleFormSubmit = () => {
    refreshValues()
  }
  const inputTotal = watchedCosts?.reduce(
    (acc, curr) => acc + parseFloat(curr.approved),
    0
  )
  setValue("inputTotal", inputTotal)

  const participantCostTotal = watchedCosts?.reduce((acc, curr) => {
    return acc + parseFloat(curr.participantCost || "0")
  }, 0)
  setValue("participantCostTotal", participantCostTotal)

  let calculatedStaticTotal = caseInput.staticTotal

  calculatedStaticTotal = watchedCosts?.reduce(
    (acc, curr) => acc + parseFloat(curr.appliedFor),
    0
  )
  setValue("staticTotal", calculatedStaticTotal)

  useEffect(() => {
    refreshValues()
  }, [calculatedStaticTotal])

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      control={control}
      errors={errors}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    >
      <form onChange={handleSubmit(handleFormSubmit)}>
        {isModal && (
          <Modal
            title={t("remove-activity-warning")}
            customHeight="15%"
            customWidth="25%"
            onClose={() => setModal(false)}
          >
            <ModalButtonContainer>
              <PrimaryButton onClick={onDelete}>
                {t("yes-remove-activity")}
              </PrimaryButton>
              <SecondaryButton onClick={() => setModal(false)}>
                {t("no-cancel")}
              </SecondaryButton>
            </ModalButtonContainer>
          </Modal>
        )}
        <StyledActivity>
          {canDelete && (
            <ButtonContainer>
              <TextButton
                onClick={() => setModal(true)}
                padding={false}
                paddingConfiguration={false}
              >
                <Icons.Trashcan />
              </TextButton>
            </ButtonContainer>
          )}
          <Header>
            <div>
              {isDynamicCostBased ? (
                <Controller
                  name={`activityTitle`}
                  control={control}
                  render={({
                    field: { name, value, onChange, onBlur, ...rest },
                  }) => (
                    <TextField
                      label={t("activity-name")}
                      onChange={(value) => {
                        value = value.substring(0, 64)
                        onChange(value)
                      }}
                      value={value}
                      field={{ ...rest, value, name, onChange, onBlur }}
                    />
                  )}
                />
              ) : (
                <Heading>{caseInput?.activityTitle}</Heading>
              )}
            </div>
          </Header>
          <LineList>
            <ActivityTypeLine
              t={t}
              activityTypeText={t("activity-type-heading")}
              supportRegimeText={t("choose-support-regime")}
              index={index}
              fouActivity={caseInput?.fouActivity}
              isAgriculture={category === "agriculture"}
              isBioEnergy={category === "bioenergy"}
              isDtil={category === "agricultureadditionalbusiness"}
              isExportPromotion={category === "exportpromotion"}
              isShippingAndFishery={category === "shippingandfishery"}
              rest={rest}
              activityStart={activityStart}
              activityEnd={activityEnd}
              supportRegimes={mappedSupportRegimes}
            />
          </LineList>
          {activityType !== "noOption" && supportRegime !== "noOption" ? (
            <>
              {fields.length > 0 && (
                <LineList>
                  <ProductLineHeader
                    key={`${index}productHeader`}
                    product={t("product")?.toUpperCase()}
                    amount={t("amount")?.toUpperCase()}
                    fundingIntensity={t("funding-intensity")?.toUpperCase()}
                    productType={t("product-type")?.toUpperCase()}
                    header={true}
                    options={options}
                    t={t}
                    supportRegime={supportRegime}
                  />

                  {fields?.map((field, i) => {
                    const productLine = getValues()?.products?.at(i)
                    const {
                      product,
                      amount,
                      fundingIntensity,
                      productType,
                      subproducts,
                    } = productLine
                    return (
                      <ProductLine
                        key={field.id}
                        product={product}
                        amount={amount}
                        fundingIntensity={fundingIntensity}
                        productType={productType}
                        subproducts={subproducts}
                        index={i}
                        t={t}
                        options={options}
                        onDelete={() => remove(i)}
                        inputTotal={inputTotal}
                        refreshValues={refreshValues}
                        supportRegime={supportRegime}
                        isDynamicCostBased={isDynamicCostBased}
                      />
                    )
                  })}
                </LineList>
              )}
              <TextButton onClick={addProduct}>
                {t("add-lineItems-button-text")}
              </TextButton>
            </>
          ) : (
            <InfoLine text={t("activity-supportregime-needed")} />
          )}
          <Divider />
          <LineList>
            <ActivityLine
              costType={t("cost-type")?.toUpperCase()}
              projectCost={t("project-cost")?.toUpperCase()}
              basisForSupport={t("support-basis")?.toUpperCase()}
              participantCost={t("participant-cost")?.toUpperCase()}
              header={true}
              refreshValues={refreshValues}
              setValue={setValue}
              control={control}
              register={register}
              showParticipantsColumn={showParticipantsColumn}
            />

            {costFields.map((cost, i) => {
              const activityLine = getValues()?.costs?.at(i)
              return (
                <ActivityLine
                  costType={t(activityLine.name)}
                  projectCost={activityLine.appliedFor}
                  index={i}
                  currency={currency}
                  key={`${index}${cost}${i}`}
                  isCustomActivity={isCustomActivity}
                  refreshValues={refreshValues}
                  setValue={setValue}
                  control={control}
                  register={register}
                  isDynamicCostBased={isDynamicCostBased}
                  showParticipantsColumn={showParticipantsColumn}
                />
              )
            })}
            <ActivityLine
              costType={t("sum-all-activities").toUpperCase()}
              projectCost={addThousandSeparator(calculatedStaticTotal)}
              basisForSupport={addThousandSeparator(caseInput?.inputTotal)}
              participantCost={addThousandSeparator(participantCostTotal)}
              header={true}
              currency={currency}
              showParticipantsColumn={showParticipantsColumn}
            />
          </LineList>
        </StyledActivity>
      </form>
    </FormProvider>
  )
}
export default Activity

const StyledActivity = styled.div`
  padding: 20px;
`

const Divider = styled.div`
  border-top: 1px solid #e2e2e1;
  margin: 20px 0;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`

const ModalButtonContainer = styled.div`
  display: flex;

  justify-content: space-evenly;
  padding: 15px;
`
