import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import { TransparentButton, SecondaryButton } from "@flow/buttons"
import { FlexRow, SelectContainer } from "../ControlComponents"
import { Select, TextArea } from "@flow/forms"
import { getAll } from "../../../../customer/util/maintenanceDecisionRequest"
import { v4 as uuidv4 } from "uuid"
import MultiSelect from "../../uploadStatisticalData/Selects/Maintenance"
import { mapAgreementsToOptions } from "../../utils/mapMaintenance"

const CreateDecisionText = ({
  t,
  onAddDecision,
  onCancelEdit,
  agreements = [],
  decision = null,
}) => {
  const [categoryData, setCategoryData] = useState()
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [subSubCategories, setSubSubCategories] = useState([])
  const [decisions, setDecisions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedSubCategory, setSelectedSubCategory] = useState()
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState()
  const [selectedDecision, setSelectedDecision] = useState()
  const [text, setText] = useState()
  const [selectedAgreements, setSelectedAgreements] = useState([])
  const [agreementOptions, setAgreementOptions] = useState(
    mapAgreementsToOptions(agreements, t) || []
  )
  const propsHaveCollateralAgreements = useMemo(
    () => agreements.length > 0,
    [agreements]
  )

  useEffect(() => {
    async function getCategories() {
      const data = await getAll()
      setCategoryData(data)
      const categories = formatSelectOptions(data)
      setCategories(categories)
    }

    getCategories()
  }, [])

  useEffect(() => {
    if (decision === null) return
    setText(decision.decision.text)
  }, [decision])

  const formatSelectOptions = (categories) => {
    const c = categories.map((category) => ({
      label: category.title,
      value: category.id,
    }))

    if (c.length <= 0) return []

    return c
  }

  const handleCategoryChange = (e, value) => {
    if (!value) return

    setSelectedCategory(value)
    setSelectedSubCategory()
    setSelectedSubSubCategory()
    setSelectedDecision()
    setText("")

    const subCategories = categoryData.find(
      (category) => category.id === value
    ).subCategories
    const formattedSubCategories = formatSelectOptions(subCategories)
    setSubCategories(formattedSubCategories)
  }

  const handleSubCategoryChange = (e, value) => {
    if (!value) return

    setSelectedSubCategory(value)
    setSelectedSubSubCategory()
    setSelectedDecision()
    setText("")

    const subSubCategories = categoryData
      .find((category) => category.id === selectedCategory)
      .subCategories.find(
        (subCategory) => subCategory.id === value
      ).subCategories2
    const formattedSubSubCategories = formatSelectOptions(subSubCategories)

    setSubSubCategories(formattedSubSubCategories)
  }

  const handleSubSubCategoryChange = (e, value) => {
    if (!value) return

    setSelectedSubSubCategory(value)
    setSelectedDecision()

    const maintenanceDecisions = categoryData
      .find((category) => category.id === selectedCategory)
      .subCategories.find(
        (subCategory) => subCategory.id === selectedSubCategory
      )
      .subCategories2.find((subSub) => subSub.id === value).maintenanceDecisions
    const formattedDecisions = maintenanceDecisions.map((decision) => ({
      label: decision.description.title,
      value: decision.id,
    }))

    setDecisions(formattedDecisions)
  }

  const handleDecisionChange = (e, value) => {
    if (!value) return

    setSelectedDecision(value)

    const decisionData = categoryData
      .find((category) => category.id === selectedCategory)
      .subCategories.find(
        (subCategory) => subCategory.id === selectedSubCategory
      )
      .subCategories2.find((subSub) => subSub.id === selectedSubSubCategory)
      .maintenanceDecisions.find((decision) => decision.id === value)

    setText(decisionData.description.content)
  }

  const canSubmit = useMemo(() => {
    if (decision !== null && text) return true
    if (!text) return false
    if (!categories || categories.length <= 0) return false
    if (!selectedCategory || !selectedSubCategory || !selectedSubSubCategory)
      return false
    if (propsHaveCollateralAgreements && selectedAgreements.length <= 0)
      return false

    return true
  }, [
    text,
    categories,
    selectedCategory,
    selectedSubCategory,
    selectedSubSubCategory,
    propsHaveCollateralAgreements,
    selectedAgreements,
    decision,
  ])

  const createNewDecision = () => {
    if (decision === null) {
      const category = categoryData.find((c) => c.id === selectedCategory)
      const subCategory = category.subCategories.find(
        (sub) => sub.id === selectedSubCategory
      )
      const subSubCategory = subCategory.subCategories2.find(
        (subSub) => subSub.id === selectedSubSubCategory
      )
      const decision = subSubCategory.maintenanceDecisions.find(
        (d) => d.id === selectedDecision
      )
      const filteredAgreements = agreements.filter((agreement) => {
        const exists = selectedAgreements.find(
          (selected) =>
            selected.value ===
            (agreement.internalId || agreement.data.internalId)
        )
        return !!exists
      })

      const data = {
        id: uuidv4(),
        category: {
          id: category.id,
          title: category.title,
        },
        subCategory: {
          id: subCategory.id,
          title: subCategory.title,
        },
        subSubCategory: {
          id: subSubCategory.id,
          title: subSubCategory.title,
        },
        decision: {
          id: decision.id,
          title: decision.description.title,
          text: text,
        },
      }
      onAddDecision(data, true, filteredAgreements)
    } else {
      const data = {
        id: decision.id,
        allowModification: false,
        isModified: true,
        decision: {
          title: decision.decision.title,
          text: text,
        },
      }
      onAddDecision(data, false)
    }
  }

  return (
    <>
      {propsHaveCollateralAgreements && (
        <FlexRow>
          <MultiSelect
            onChange={(selected) => setSelectedAgreements(selected)}
            options={agreementOptions}
          />
        </FlexRow>
      )}
      {decision === null && (
        <FlexRow>
          <SelectContainer>
            <Select
              id="category"
              label={t("category")}
              onChange={handleCategoryChange}
              options={categories}
              value={selectedCategory}
              width={"100%"}
              showBlankOption={true}
            />
          </SelectContainer>
          <SelectContainer>
            <Select
              id="subCategory"
              label={t("subcategory")}
              onChange={handleSubCategoryChange}
              options={subCategories || []}
              value={selectedSubCategory}
              width={"100%"}
              showBlankOption={true}
              disabled={!selectedCategory}
            />
          </SelectContainer>
          <SelectContainer>
            <Select
              id="subSubCategory"
              label={t("decisionType")}
              onChange={handleSubSubCategoryChange}
              options={subSubCategories || []}
              value={selectedSubSubCategory}
              width={"100%"}
              showBlankOption={true}
              disabled={!selectedSubCategory}
            />
          </SelectContainer>
          <SelectContainer>
            <Select
              id="decision"
              label={t("text")}
              onChange={handleDecisionChange}
              options={decisions || []}
              value={selectedDecision}
              width={"100%"}
              showBlankOption={true}
              disabled={!selectedSubSubCategory}
            />
          </SelectContainer>
        </FlexRow>
      )}
      <StyledTextArea
        name={"termTextArea"}
        onChange={(e) => setText(e.target.value)}
        value={text}
        placeholder={t("term-text-placeholder")}
        disabled={!selectedDecision && decision === null}
      />
      <>
        <ButtonContainer>
          <SecondaryButton
            form="addDecisionTextForm"
            onClick={() => createNewDecision()}
            disabled={!canSubmit}
          >
            {t("add-special-terms-button-text")}
          </SecondaryButton>
          <TransparentButton onClick={() => onCancelEdit()}>
            {t("cancel-special-terms-button-text")}
          </TransparentButton>
        </ButtonContainer>
      </>
    </>
  )
}

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 140px;
  border-radius: 5px;
  background: white;
  margin-bottom: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
`

export default CreateDecisionText
