import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import RequestedDocArchive from "../components/common/RequestedDocArchive"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { returnFirstArgWithValue } from "../util/returnValue"
import styled from "styled-components"

const CheckDocumentation = ({ flow, t, task, save, schema, complete }) => {
  const { data, defaults } = task
  const { applicationSummary, specialConditions } = task?.context || {}
  const [isProcessing, setProcessing] = useState(false)

  const handleSave = () => {
    setProcessing(true)
    save(
      { formData },
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }
  const handleComplete = (values) => {
    complete(
      {
        comment: formData?.comment,
        documentationId: task?.variables?.documentationId,
      },
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const onFormChange = (values) => {
    setFormData(values)
  }
  const stateData = {
    ...(flow.data?.analysis?.analyzeApplication || {}),
    deliveries: specialConditions || {},
  }
  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data?.formData, stateData, defaults) || {}
  )

  return (
    <Layout forceHeight>
      <MainContent>
        <RequestedDocArchive
          documentations={
            flow?.data?.analysis?.updateCustomerInformation?.documentation
          }
          t={t}
        />
      </MainContent>

      <Context flow={flow} context={applicationSummary}>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton type="submit" isLoading={isProcessing}>
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}

const MainContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
  padding: 25px 0 0 0;
  overflow: scroll;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

export default withTranslation("maintenance")(CheckDocumentation)
