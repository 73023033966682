import React, { useState } from "react"
import styled from "styled-components"
import Tabs from "../components/common/Tabs"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { FormProvider, useForm } from "react-hook-form"
import SpecialTermsMaintenance from "../components/maintenance/SpecialTermsMaintenance"
import ErrorText from "../components/common/ErrorText"
import CustomCheckListForm from "../components/controlTerms/CheckListForm"

const getMappedTerms = (context) => {
  const mappedTerms = [
    ...context?.specialTerms?.userAdded.map((e) => {
      return {
        text: e.termText,
        mandatory: false,
      }
    }),
    ...context?.specialTerms?.mandatory.map((e) => {
      return {
        text: e.termText,
        mandatory: true,
      }
    }),
  ]
  return mappedTerms
}

const ControlTerms = ({ task, flow, t, schema, save, complete, user }) => {
  const { data, context } = task
  const [formData, setFormData] = useState(data?.formData)
  const [isProcessing, setProcessing] = useState(false)
  const currentUser = user.profile?.name
  const [error, setError] = useState()

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    ...rest
  } = useForm({
    defaultValues: {
      ...(task?.data
        ? task.data
        : {
            customChecklistData: [],
            basicChecklistData: [
              {
                text: "Lånet er ajour",
              },
              {
                text: "Særvilkår er kontrollert",
              },
              {
                text: "Bekreftelse/dokumentasjon fra samfinansierende bank er innhentet",
              },
              {
                text: "Koordineringsavtale",
              },
            ],
            specialTermsData: getMappedTerms(context),
          }),
    },
  })

  const handleComplete = () => {
    const values = getValues()
    const data = {
      ...formData,
      ...values,
    }

    const isNotChecked = Object.values(data)
      .flat(Infinity)
      ?.some((x) => !x.checklistControlled && !x.checklistNotRelevant)

    if (isNotChecked) {
      setError(t("control-terms-error"))
      setProcessing(false)
    } else {
      setError()
      setProcessing(true)
      complete(
        data,
        () => {
          setProcessing(false)
        },
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    }
  }

  const handleSave = () => {
    const values = getValues()

    const data = {
      ...formData,
      ...values,
    }

    setProcessing(true)
    save(
      data,
      () => {
        setProcessing(false)
      },
      () => {
        setProcessing(false)
      }
    )
  }

  const options = [
    {
      id: "controlTerms",
      title: "Kontroller særvilkår",
      component: (
        <MainContainer>
          <FormProvider
            handleSubmit={handleSubmit}
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            {...rest}
          >
            <CustomCheckListForm
              t={t}
              currentUser={currentUser}
              title={"Egendefinert sjekkliste"}
              formId={"customChecklistData"}
            />
            <CustomCheckListForm
              t={t}
              currentUser={currentUser}
              readOnly
              formId={"basicChecklistData"}
            />
            <CustomCheckListForm
              t={t}
              currentUser={currentUser}
              readOnly
              title={"Særvilkår"}
              formId={"specialTermsData"}
            />
          </FormProvider>
        </MainContainer>
      ),
    },
    {
      id: "specialTerms",
      title: "Særvilkår",
      component: (
        <MainContainer>
          <SpecialTermsMaintenance
            data={context?.specialTerms?.userAdded}
            t={t}
            currentUser={currentUser}
            readOnly
          />
        </MainContainer>
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContent>
        <Tabs options={options} />
      </MainContent>
      <Context flow={flow} context={context.applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing}
            onChange={(formData) => setFormData(formData)}
            onSubmit={handleComplete}
          >
            {error && <ErrorText error={error} t={t} />}
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={isProcessing}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={() => handleSave()}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}

export default withTranslation("maintenance")(ControlTerms)

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  gap: 1rem;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`
