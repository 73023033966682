import React, { useState, useMemo } from "react"
import styled from "styled-components"
import {
  DecisionBody,
  DecisionContainer,
  DecisionHeader,
} from "../TermsAndSecurity/decisionTexts/Change"
import { getTitle } from "../utils/mapMaintenance"
import {
  DecisionWrapper,
  TextWrapper,
} from "../TermsAndSecurity/decisionTexts/DecisionText"
import { FlexRow } from "../TermsAndSecurity/ControlComponents"
import { Colors } from "@flow/style"
import DownloadFileButton from "../common/DownloadFileButton"

export const DownloadDecisionLetter = ({ t, decisionText }) => {
  return (
    <Item>
      <p>{t("download-decision-letter")}</p>
      <StyledDownload>
        <DownloadFileButton
          title={t(decisionText?.filename)}
          fileId={decisionText?.storeID}
          key={decisionText?.storeID}
        />
      </StyledDownload>
    </Item>
  )
}
const StyledDownload = styled.div`
  color: ${Colors.Grey1};
  padding: 0.1em 0.1em 0.1em 0;
  font-size: small;
  line-height: 0.8em;
  width: 100%;

  button {
    white-space: pre-wrap;
    width: 100%;
  }
  svg {
    display: none;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  padding-top: 1em;
  overflow-x: hidden;
`

const DecisionTextsList = ({ t, changes }) => {
  // Merge userAdded and generated decisions into one array
  const formattedChanges = useMemo(() => {
    const updatedChanges = changes?.map((change) => {
      const userAdded = change?.decisionTexts?.userAdded?.map((decision) => {
        return {
          ...decision,
          type: "userAdded",
        }
      })
      const generated = change?.decisionTexts?.generated?.map((decision) => {
        return {
          ...decision,
          type: "generated",
        }
      })
      const merged = [...userAdded, ...generated]
      return {
        ...change,
        decisionTexts: merged,
      }
    })
    return updatedChanges
  }, [changes])

  return (
    <>
      <ChangeTitleContainer hasChanges={formattedChanges?.length > 0}>
        {formattedChanges?.length > 0 ? (
          <p>ENDRINGER</p>
        ) : (
          <p>INGEN ENDRINGER</p>
        )}
      </ChangeTitleContainer>
      <div>
        {formattedChanges?.map((change) => {
          return (
            <DecisionContainer>
              <DecisionHeader hasChanges={formattedChanges?.length > 0}>
                <DetailBox>
                  <Title>{getTitle(change, t)}</Title>
                </DetailBox>
              </DecisionHeader>
              {change?.decisionTexts?.length > 0 && (
                <DecisionBody>
                  {change?.decisionTexts?.map((decision) => {
                    return (
                      <DecisionWrapper key={decision?.id}>
                        {decision?.type === "userAdded" ? (
                          <FlexRow>
                            <FlexRow>
                              <TextWrapper>
                                <GreyText>{t("category")}</GreyText>
                                <BlackText>
                                  {decision.category?.title}
                                </BlackText>
                              </TextWrapper>
                            </FlexRow>
                            <FlexRow>
                              <TextWrapper>
                                <GreyText>{t("subcategory")}</GreyText>
                                <BlackText>
                                  {decision?.subCategory?.title}
                                </BlackText>
                              </TextWrapper>
                            </FlexRow>
                            <FlexRow>
                              <TextWrapper>
                                <GreyText>{t("decisionType")}</GreyText>
                                <BlackText>
                                  {decision?.subSubCategory?.title}
                                </BlackText>
                              </TextWrapper>
                            </FlexRow>
                            <FlexRow>
                              <TextWrapper>
                                <GreyText>{t("text")}</GreyText>
                                <BlackText>
                                  {decision?.decision?.title}
                                </BlackText>
                              </TextWrapper>
                            </FlexRow>
                          </FlexRow>
                        ) : (
                          <FlexRow>
                            <TextWrapper>
                              <BlackText>{decision?.decision?.title}</BlackText>
                            </TextWrapper>
                          </FlexRow>
                        )}
                        <DecisionTextContent>
                          {decision?.decision?.text}
                        </DecisionTextContent>
                      </DecisionWrapper>
                    )
                  })}
                </DecisionBody>
              )}
            </DecisionContainer>
          )
        })}
      </div>
    </>
  )
}
export default DecisionTextsList

const BlackText = styled.div`
  color: black;
  white-space: nowrap;
`
const GreyText = styled.div`
  color: ${Colors.Grey1};
  text-transform: uppercase;
`

const Title = styled.h3`
  color: black;
  font-weight: 400;
`
const DetailBox = styled.div`
  margin: 10px 0px;
`

const DecisionTextContent = styled.div`
  background-color: white;
  margin-bottom: 10px;
  color: #6c6c6c;
`

const ChangeTitleContainer = styled.div`
  padding: 14px 0px;
  border-bottom: ${({ hasChanges }) =>
    hasChanges ? "1px solid #e5edff;" : "none"};
`
