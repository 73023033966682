import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import Tabs from "../components/common/Tabs"
import useMunicipalityLookup from "../components/common/useMunicipalityLookup"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import _ from "lodash"
import ErrorText from "../components/common/ErrorText"
import Notice from "../components/common/Notice"
import { Colors } from "@flow/style"
import { useInsightAppInitialized } from "../components/insight/useInsightAppInitialized"
import getIntroductoryEvaluationOptions from "../components/PerformIntroductoryEvaluation/getIntroductoryEvaluationOptions"
import { handleComplete as completeTask } from "../components/BaseComponent/utils/mutations"
import {
  translateMapping,
  removeInsightSubschemas,
} from "../components/insight/insightModuleUtil"
import { formatDate } from "../components/utils/formatDate"
import { usePrevious } from "../components/utils/hooks"

const PerformIntroductoryEvaluation = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
  user,
  updateCase,
}) => {
  const {
    insightComponentData,
    templates,
    tabs,
    applicationSummary,
    context,
    isExtraordinaryFinancing,
    category,
  } = task?.context
  const [app, setApp] = useState(null)
  const [isLoading, isErrored] = useInsightAppInitialized(app)
  const [taskData, setTaskData] = useState({
    ..._.merge(
      translateMapping(insightComponentData, t),
      { templates },
      task?.data || {},
      flow.data.mapped?.engagement
    ),
    creditGroup: flow.data.mapped?.creditGroup,
  })
  const [errors, setErrors] = useState([])
  const [errorsForVekstgaranti, setErrorsForVekstgaranti] = useState([])

  const [isProcessing, setProcessing] = useState(false)
  const [previousTemplate, setPreviousTemplate] = useState(
    taskData.rejectionTemplate
  )
  const [rerenderId, setRerenderId] = useState(0)

  const [time, setTime] = useState(Date.now())
  const [showReload, setShowReload] = useState(false)

  const isRefetchingEngagements = flow.data?.tmp?.creditGroup?.isRefetching
  const prevIsRefetching = usePrevious(isRefetchingEngagements)

  /**
   * EEA evaluation
   * @type {[Insight.EeaData, function]}
   */
  const [eeaData, setEeaData] = useState(
    taskData.eeaData ?? task.context.insightComponentData.eeaData
  )

  /**
   * @param {Insight.EeaData} data
   * @returns {void}
   */
  const handleEeaEvaluationChanged = (data) => {
    const isEeaValid = Boolean(data?.projectScore && data?.applicantType)
    data.isEeaValid = isEeaValid
    setEeaData(data)
  }

  useEffect(() => {
    if (prevIsRefetching && !isRefetchingEngagements) {
      setShowReload(true)
    }
  }, [isRefetchingEngagements])

  useEffect(() => {
    if (isRefetchingEngagements) {
      const interval = setInterval(() => {
        updateCase()
        setTime(Date.now())
      }, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [isRefetchingEngagements])

  // Get municipality lookup function and current municipality name
  let municipality = taskData?.caseOverview?.municipality
  const [municipalityName, municipalityLookup] = useMunicipalityLookup(
    municipality?.code
  )
  taskData.whoDidIt = user?.profile?.email

  useEffect(() => {
    if (
      taskData.rejectionTemplate !== previousTemplate &&
      taskData.rejectionTemplate
    ) {
      const newData = {
        ...taskData,
        rejectionText: templates[taskData.rejectionTemplate],
      }
      setPreviousTemplate(taskData.rejectionTemplate)
      setTaskData(newData)
      setRerenderId(rerenderId + 1)
    }
  }, [taskData])

  if (category !== "counterguarantee") {
    // Policybrudd //
    const policies = flow.data?.decisionTables?.prescore?.output?.map(
      (policy) => policy.decision
    )

    taskData.policy = {
      policies,
    }
  }

  // Update municipality name in taskdata if name has changed
  useEffect(() => {
    if (municipality && municipalityName) {
      municipality.name = municipalityName
      setTaskData({ ...taskData })
    }
  }, [municipalityName])
  const options = getIntroductoryEvaluationOptions({
    order: tabs,
    context,
    taskData,
    onAppChange: setApp,
    t,
    flow,
    municipalityLookup,
    eeaData,
    handleEeaEvaluationChanged,
  })

  const updateTaskData = (taskData, data) => {
    const mergedData = _.merge(taskData || {}, data)
    setTaskData({
      ...mergedData,
      creditGroup: flow.data.mapped?.creditGroup,
      eeaData,
    })
    return mergedData
  }

  const handleComplete = async (values) => {
    let data = updateTaskData(taskData, values)
    data = updateTaskData(data, app?.getCurrentState())
    let errors = []
    setErrors([])
    if (category === "counterguarantee") {
      const validations = data?.isEngagementAnsweredReject
      if (!validations && errorsForVekstgaranti.length === 0) {
        errorsForVekstgaranti.push("engagementAnswered")
      }
      if (validations) {
        setErrorsForVekstgaranti([])
      }
    }

    if (isExtraordinaryFinancing) {
      delete data.trlAndBrl
    }
    if (data?.approvalStatus === "OK" || category === "counterguarantee") {
      const validationsForAll = data?.validations
      const isSchemaValid = data?.isSchemaValid
      if (!isSchemaValid) {
        Object.keys(validationsForAll || {}).forEach((key) => {
          if (!validationsForAll[key]) {
            errors.push(key)
          }
        })
      }
    }
    const { approvalStatus, rejectionTemplate, rejectionText } = data

    if (!approvalStatus && category !== "counterguarantee") {
      errors.push("choose-payout-decision")
    } else if (approvalStatus === "REJECT") {
      if (!rejectionTemplate) {
        errors.push("choose-rejection-template")
      }
      if (!rejectionText) {
        errors.push("add-rejection-text")
      }
    }

    if (errors.length > 0 || errorsForVekstgaranti.length > 0) {
      setErrors(errors)
      setProcessing(false)
      return
    }
    setProcessing(true)
    // Remove unused data
    if (data.approvalStatus !== "REJECT") {
      delete data.rejectionTemplate
      delete data.rejectionText
    }

    if (data.approvalStatus === "MOREINFO") {
      delete data.comment
    } else {
      delete data.commentToCustomer
    }
    delete data.templates
    delete data.isInitialized
    delete data.isSchemaValid
    delete data.isEngagementAnsweredReject

    completeTask(complete, data, setProcessing)
  }
  const handleSave = async () => {
    const state = app?.getCurrentState()
    setProcessing(true)
    save(
      { ...taskData, ...state, eeaData },
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }
  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <Tabs
          defaultTab="analyze"
          options={options}
          loading={isLoading || isRefetchingEngagements}
          showReloadButton={showReload}
          disabled={isLoading}
          onChange={(tab) => {
            try {
              updateTaskData(taskData, app?.getCurrentState())
              setErrors([])
              setApp(null)
            } catch (e) {}
          }}
        />
      </BMAnalyzeContent>
      <Context flow={flow} context={applicationSummary}>
        <ReactForm
          rerenderId={rerenderId}
          schema={removeInsightSubschemas(_.cloneDeep(schema))}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => updateTaskData(taskData, values)}
        >
          <LoadingNotice hidden={!isRefetchingEngagements}>
            {`${t(
              "update-engagements-and-creditgroup-in-progress"
            )}  ${formatDate(time, "HH:mm:ss")}`}
          </LoadingNotice>
          <LoadingNotice hidden={!showReload}>
            {t("update-engagements-and-creditgroup-completed")}
          </LoadingNotice>

          {(isErrored ||
            errors?.length > 0 ||
            errorsForVekstgaranti.length > 0) && (
            <StyledNotice backgroundColor={Colors.FerrariLighter}>
              {errors?.map((e) => {
                return <ErrorText error={e} />
              })}
              {errorsForVekstgaranti?.map((e) => {
                return (
                  <div>
                    <ErrorText key={e}>{t(`${e}`)}</ErrorText>
                  </div>
                )
              })}
              {isErrored && (
                <div>
                  <ErrorText>{t(`failed-to-load-task`)}</ErrorText>
                </div>
              )}
            </StyledNotice>
          )}
        </ReactForm>
        <ButtonContainer>
          <PrimaryButton
            type="submit"
            disabled={
              isProcessing ||
              isLoading ||
              isErrored ||
              isRefetchingEngagements ||
              showReload
            }
            onClick={() => handleComplete(taskData)}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            disabled={
              isProcessing || isLoading || isRefetchingEngagements || showReload
            }
            onClick={() => handleSave()}
          >
            {t("save")}
          </SecondaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}

const StyledNotice = styled(Notice)`
  color: ${Colors.Ferrari};
  margin-top: 10px;
  border-radius: 10px;
`

const LoadingNotice = styled(Notice)`
  margin-top: 10px;
  color: ${Colors.Coal};
  background-color: ${Colors.OrangeLighter};
  border-radius: 10px;
`

const BMAnalyzeContent = styled.div`
  flex: 1;
  height: 100%;
  width: 1%;
  border-right: 1px solid #e4e2e2;
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
export default PerformIntroductoryEvaluation
