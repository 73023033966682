import { withTranslation } from "react-i18next"
import styled from "styled-components"
import React, { useState } from "react"
import Heading from "../common/Heading"
import ReactForm from "../common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import GuarantorCard from "../bail/GuarantorCard"
import { personFirstPred } from "../../util/guarantorUtils"
import DownloadFileButton from "../common/DownloadFileButton"
import { Context } from "../common/Layout"

const PrintAndSendToSignOld = ({
  t,
  guarantors,
  task,
  schema,
  save,
  complete,
  flow,
}) => {
  const [taskData, setTaskData] = useState(task.data || task.defaults)
  const [isProcessing, setProcessing] = useState(false)

  const handleSave = (values) => {
    setProcessing(true)

    save(
      taskData,
      () => {},
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)
    complete(
      taskData,
      () => {},
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const relevantDocuments =
    task.context.relevantDocuments
      ?.sort((a, b) => a.documentType.localeCompare(b.documentType))
      ?.map((x) => ({ title: x.documentType, storeID: x.storeID })) || []

  return (
    <>
      <MainContainer>
        <Heading>{t("guarantors")}</Heading>
        <GuarantorContainer>
          {guarantors.sort(personFirstPred).map((x, i) => (
            <GuarantorCard readonly t={t} guarantor={x} key={i}></GuarantorCard>
          ))}
        </GuarantorContainer>
        <div>
          <Heading>{t("relevant-documents")}</Heading>
          <Row>
            {relevantDocuments
              ?.filter((x) => !!x)
              ?.map((x, i) => (
                <DownloadFileButton
                  title={t(x?.title)}
                  fileId={x?.storeID}
                  key={i}
                />
              ))}
          </Row>
        </div>
      </MainContainer>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={schema}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => setTaskData(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave(taskData)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </>
  )
}
const MainContainer = styled.div`
  padding: 10px 30px;
  width: 100%;
  overflow-y: auto;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

const GuarantorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`
const Row = styled.div`
  display: flex;
  gap: 15px;
`
export default withTranslation()(PrintAndSendToSignOld)
