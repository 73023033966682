import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import LevelOfInnovation from "./LevelOfInnovation"

const LevelOfInnovationContainer = ({ t }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      control={control}
      name={"levelOfInnovation"}
      key={"levelOfInnovation"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <LevelOfInnovation
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          t={t}
        />
      )}
    />
  )
}

export default LevelOfInnovationContainer
