import React, { useState } from "react"
import Sidebar from "../../common/Sidebar"
import { Tabs } from "@flow/components"
import RequestedDocArchive from "../../common/RequestedDocArchive"
import Layout, { Context } from "../../common/Layout"
import styled from "styled-components"
import ErrorContext from "../../common/Error"
import { validate } from "../validateComponent"
import EvaluateBasisAdditionalProduct from "./EvaluateBasisAdditionalProduct"
import { evaluateBasisAdditionalProductValidationSchema } from "./EvaluateBasisAdditionalProduct.validationSchema"
import _ from "lodash"
import { returnFirstArgWithValue } from "../../../util/returnValue"
import BlueDot from "../../common/BlueDot"
import AssessmentComments from "../../common/AssessmentComments"
import {
  AgreementProvider,
  useCreateAgreementStore,
} from "../lineItems/useProductDraft"
import AvailableFrames from "../AvailableFramesTab/AvailableFrames"

const EvaluateBasisForSupportProduct = (props) => {
  const {
    flow,
    task,
    t,
    save,
    complete,
    additionalProductCategory,
    onTrigger,
    updateCase,
  } = props
  const {
    stateCaseComment,
    previouslyRequestedDocuments,
    applicationSummary,
    amountAppliedFor,
    productConfiguration,
    extraordinaryFinancingProduct,
    extraordinaryFinancingCategory,
    agreements,
    data: stateData,
  } = task?.context

  const [dataFromForm, setDataFromForm] = useState(
    returnFirstArgWithValue(task.data, stateData)
  )

  const agreementStore = useCreateAgreementStore(
    returnFirstArgWithValue(task.data?.agreements, agreements, {})
  )
  const [activeTab, setActiveTab] = useState("evaluateBasis")

  //validation
  const [errors, setErrors] = useState([])
  const [showValidation, setShowValidation] = useState(false)
  const [formErrors, setFormErrors] = useState(true)
  const [showFormErrors, setShowFormErrors] = useState(false)

  //submitting or saving
  const [isLoading, setLoading] = useState(false)

  const [caseComment, setCaseComment] = useState(
    returnFirstArgWithValue(task?.data?.caseComment, stateCaseComment, "")
  )

  const { showAvailableFramesTab } = flow.data.analysis
  const availableFrames = flow.data.analysis?.availableFrames

  const updateCaseComment = (value) => {
    setCaseComment(value)
  }

  const onSaveAndComplete = (e) => {
    e.preventDefault()
    setLoading(true)
    save(
      { ...dataFromForm, caseComment, agreements: agreementStore.getAll() },
      () => {
        setLoading(false)
      },
      () => {
        console.error("Could not complete task")
        setLoading(false)
      }
    )
  }

  const validateForm = async (values) => {
    let validatedValues = {}

    try {
      validatedValues =
        await evaluateBasisAdditionalProductValidationSchema.validate(values, {
          abortEarly: false,
        })
    } catch (e) {
      setFormErrors(
        e.inner.reduce((acc, curr) => {
          acc[curr.path] = { message: curr.message }
          return acc
        }, {})
      )
    }
    //if validation complete (values returned to validatedValues), set errors to {}
    if (!_.isEmpty(validatedValues)) {
      setFormErrors({})
    }
    return validatedValues
  }

  const formatDataForComplete = (values) => {
    return {
      agreements: agreementStore.getAll(),
      activities: [
        {
          ...values,
          activityTitle: t("additional-product-header"),
          activityName: t("additional-product-header"),
          products: [values?.products],
          staticTotal: parseInt(amountAppliedFor?.grant),
          inputTotal: parseInt(values?.updatedCost),
          costs: [
            {
              name: "Fordringer",
              appliedFor: amountAppliedFor?.grant?.toString(),
              approved: values?.updatedCost,
            },
          ],
        },
      ],
    }
  }

  const onComplete = async (e) => {
    const validatedValues = await validateForm(dataFromForm)
    const formattedDataFromForm = formatDataForComplete(dataFromForm)

    const { errors } = validate(
      formattedDataFromForm,
      false,
      productConfiguration,
      t,
      agreementStore.getAll(),
      true
    )

    setShowValidation(true)
    setShowFormErrors(true)
    setErrors(errors)

    //no errors for products //no errors from form //has case comment //values return from form validation
    if (
      errors?.length === 0 &&
      _.isEmpty(formErrors) &&
      !!caseComment &&
      !_.isEmpty(validatedValues)
    ) {
      const data = {
        ...formattedDataFromForm,
        caseComment,
        summaries: {
          totalApprovedCosts: parseFloat(dataFromForm?.updatedCost),
          totalOriginalCosts: parseFloat(amountAppliedFor?.grant),
          amountSummary: {
            grant: {
              totalAppliedFor: parseFloat(amountAppliedFor?.grant),
              totalGranted: parseFloat(dataFromForm?.products?.amount),
            },
            loan: {
              totalAppliedFor: 0,
              totalGranted: 0,
            },
          },
          //only one activity for extraordinary financing.
          startDate: formattedDataFromForm?.activities?.at(0)?.activityStart,
          endDate: formattedDataFromForm?.activities?.at(0)?.activityEnd,
        },
      }

      e.preventDefault()
      setLoading(true)
      complete(
        data,
        () => {
          setLoading(false)
        },
        () => {
          console.error("Could not complete task")
          setLoading(false)
        }
      )
    }
  }

  const handleChange = (values) => {
    const { errors: validationErrors } = validate(
      formatDataForComplete(values),
      false,
      productConfiguration,
      t,
      agreementStore.getAll(),
      true
    )
    setErrors(validationErrors)
    validateForm(values)
    setDataFromForm(values)
  }
  const options = [
    {
      id: "evaluateBasis",
      title: "Sett støttegrunnlag",
      component: (
        <EvaluateBasisAdditionalProduct
          t={t}
          options={{
            productConfiguration,
          }}
          handleChange={handleChange}
          showErrors={showFormErrors}
          errors={formErrors}
          extraordinaryFinancingProduct={extraordinaryFinancingProduct}
          amountAppliedFor={amountAppliedFor}
          dataFromForm={dataFromForm}
          category={additionalProductCategory}
          extraordinaryFinancingCategory={extraordinaryFinancingCategory}
        />
      ),
    },
    {
      id: "comments",
      title: "Etterspurt dokumentasjon",
      component: (
        <RequestedDocArchive
          documentations={previouslyRequestedDocuments}
          t={t}
        />
      ),
    },
  ]

  //only add tab if data
  const comments = flow?.data?.assessmentComments
  comments &&
    options.push({
      id: "assessments",
      title: t("assessment-history"),
      children: comments?.length ? (
        <BlueDot>{comments?.length}</BlueDot>
      ) : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments comments={flow?.data?.assessmentComments} t={t} />
        </AssessmentCommentsWrapper>
      ),
    })

  if (showAvailableFramesTab) {
    options.push({
      id: "availableFrames",
      title: "Tilgjengelige rammer",
      component: (
        <AvailableFrames
          availableFrames={availableFrames}
          t={t}
          onTrigger={onTrigger}
          flowId={flow.flowId}
          updateCase={updateCase}
        />
      ),
    })
  }

  const tab = options.find((x) => x.id === activeTab)
  return (
    <Layout forceHeight>
      <DisplayContent>
        <Content>
          <AgreementProvider store={agreementStore}>
            <TabWrapper>
              <Tabs options={options} onChange={(e) => setActiveTab(e)} />
            </TabWrapper>
            <TabContainer>{tab.component}</TabContainer>
          </AgreementProvider>
        </Content>
        <Context context={applicationSummary} flow={flow}>
          <Sidebar
            commentPlaceholder={t("write-here", "Skriv her...")}
            commentHelp={t("evaluate-basis-for-support-comment-help")}
            commentValue={caseComment}
            commentHeading={t(
              "explain-decision-for-subsidy-regime-and-financial-service"
            )}
            commentOnChange={updateCaseComment}
            primaryButtonName={t("complete")}
            primaryButtonProps={{
              disabled: isLoading,
              onClick: onComplete,
            }}
            secondaryButtonName={t("save-and-close")}
            secondaryButtonProps={{
              disabled: isLoading,
              onClick: onSaveAndComplete,
            }}
          >
            {!caseComment && showValidation && (
              <ErrorContext t={t} error={"comment-missing"} />
            )}
            {showValidation &&
              errors.map((e) => {
                return <ErrorContext t={t} error={e} />
              })}
          </Sidebar>
        </Context>
      </DisplayContent>
    </Layout>
  )
}

export default EvaluateBasisForSupportProduct
const TabContainer = styled.div`
  margin: 20px 0;
  flex: 1;
`
const TabWrapper = styled.div`
  margin: 0px 40px;
  margin-top: 10px;
`

const Content = styled.div`
  height: 100%;
  width: 150%;
  overflow-y: auto;
  border-right: 1px solid #e4e2e2;
`

const DisplayContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: none;
`

const AssessmentCommentsWrapper = styled.div`
  padding: 10px 40px;
  overflow-y: auto;
`
