import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import ProjectProfitability from "./ProjectProfitability"

const ProjectProfitabilityContainer = ({ t }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      control={control}
      name={"projectProfitability"}
      key={"projectProfitability"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <ProjectProfitability
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          t={t}
        />
      )}
    />
  )
}

export default ProjectProfitabilityContainer
