import React from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"
import { ProjectColors } from "../common/style/ProjectColors"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import StyledInput from "../common/inputs/StyledInput"
import { Input } from "@staccx/bento"
import _ from "lodash"
import calculateInternalInterest from "../../util/internalInterestRequest"

/**
 *
 * @typedef {object} InternalInterestDataObject
 * @property {number} fromApplication
 * @property {number} actualValue
 * @property {string} category
 *
 * @typedef {object} RequestBody
 * @property {number} fuelCostCentPerKwh
 * @property {number} maintenanceCostsCentPerKwh
 * @property {number} operatingCostsCentPerKwh
 * @property {number} efficiencyPercentage
 * @property {number} expectedProductionKwhPerYear
 * @property {number} salesPriceCentPerKwh
 * @property {number} otherRevenuePerYear
 * @property {number} investmentKr
 * @property {number} grantsKr
 * @property {number} lifetimeYear
 *
 * @typedef {object} InternalInterestData State
 * @property {InternalInterestDataObject} fuelCostCentPerKwh
 * @property {InternalInterestDataObject} maintenanceCostsCentPerKwh
 * @property {InternalInterestDataObject} operatingCostsCentPerKwh
 * @property {InternalInterestDataObject} efficiencyPercentage
 * @property {InternalInterestDataObject} expectedProductionKwhPerYear
 * @property {InternalInterestDataObject} salesPriceCentPerKwh
 * @property {InternalInterestDataObject} otherRevenuePerYear
 * @property {InternalInterestDataObject} investmentKr
 * @property {InternalInterestDataObject} grantsKr
 * @property {InternalInterestDataObject} lifetimeYear
 */

/**
 * @typedef {object} InternalInterestCalculationProps
 * @property {import("react-i18next").TFunction} t
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../types").InsightInternalInterestState>>} setInsightInternalInterestState
 * @property {object} insightInternalInterestState
 * @property {InternalInterestData} internalInterestData
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../types").InternalInterestData>>} setInternalInterestData
 * @property {import("react").Dispatch<import("react").SetStateAction<string[]>>} setErrors
 * @property {import("react").Dispatch<import("react").SetStateAction<boolean>>} setProcessing
 */

/**
 * @param {InternalInterestCalculationProps} props
 */
const InternalInterestCalculation = ({
  t,
  insightInternalInterestState,
  setInsightInternalInterestState,
  internalInterestData,
  setInternalInterestData,
  setErrors,
  setProcessing,
}) => {
  const { calculatedInterestBeforeGrant, calculatedInterestWithGrant } =
    insightInternalInterestState
  const prevInternalInterestData = React.useRef(internalInterestData)

  const onChangeActualValue = (name, value) => {
    setInternalInterestData((prev) => {
      if (value === null || value === "" || isNaN(value)) {
        return {
          ...prev,
          [name]: {
            ...prev[name],
            actualValue:
              prev[name]?.actualValue !== null ? prev[name].actualValue : 0,
          },
        }
      }
      return {
        ...prev,
        [name]: {
          ...prev[name],
          actualValue: value ?? 0,
        },
      }
    })
  }

  // Set requestcount instead of using useEffect?
  React.useEffect(() => {
    if (!_.isEqual(internalInterestData, prevInternalInterestData.current)) {
      /**
       * @type {RequestBody}
       */
      const requestBody = Object.entries(internalInterestData).reduce(
        (acc, [key, { actualValue }]) => {
          if (key === "lifetimeYear") {
            if (acc[key] === null || acc[key] === "") {
              acc[key] = 0
            }
          }
          acc[key] = parseFloat(actualValue)
          return acc
        },
        {}
      )

      setProcessing(true)
      calculateInternalInterest(requestBody)
        .then((response) => {
          setProcessing(false)

          let calculatedInterestBeforeGrant = null
          let calculatedInterestWithGrant = null

          if (response?.valid) {
            calculatedInterestBeforeGrant =
              response.calculatedInterestPercentageWithoutGrants
            calculatedInterestWithGrant = response.calculatedInterestPercentage

            setErrors((prev) =>
              prev.filter((e) => e !== "invalidInternalInterest")
            )

            setInsightInternalInterestState((prev) => ({
              ...prev,
              calculatedInterestBeforeGrant,
              calculatedInterestWithGrant,
            }))
          } else {
            setErrors((prev) => {
              if (prev.includes("invalidInternalInterest")) {
                return prev
              }

              return [...prev, "invalidInternalInterest"]
            })

            setInsightInternalInterestState((prev) => ({
              ...prev,
              calculatedInterestBeforeGrant: null,
              calculatedInterestWithGrant: null,
            }))
          }
        })
        .catch((error) => {
          setProcessing(false)
          console.error(error)
        })

      prevInternalInterestData.current = internalInterestData
    }
  }, [internalInterestData, setInsightInternalInterestState])

  const mappedInternalInterestData = Object.entries(internalInterestData)
    .map(([key, { category }]) => ({
      key,
      category,
    }))
    .reduce((acc, { key, category }) => {
      if (!acc[category]) {
        acc[category] = []
      }
      const { fromApplication, actualValue, readOnly } =
        internalInterestData[key]
      acc[category].push({
        name: key,
        fromApplication: fromApplication.toString(),
        actualValue: actualValue.toString(),
        readOnly: readOnly ?? false,
      })
      return acc
    }, {})

  return (
    <Container>
      <StyledList>
        {Object.entries(mappedInternalInterestData).map(
          ([name, fields], categoryIndex) => (
            <React.Fragment key={categoryIndex}>
              <LineItem header key={`${categoryIndex}header`}>
                <b>{t(name)}</b>
                {categoryIndex === 0 ? (
                  <>
                    <p>{t("fromApplication")}</p>
                    <p>{t("actual")}</p>
                  </>
                ) : null}
              </LineItem>
              {fields.map(
                (
                  { name, readOnly, fromApplication, actualValue },
                  fieldIndex
                ) => (
                  <LineItem key={`${categoryIndex}${fieldIndex}`}>
                    <p>{t(name)}</p>
                    <p>{addThousandSeparator(fromApplication)}</p>
                    {readOnly ? (
                      <p>{addThousandSeparator(actualValue)}</p>
                    ) : (
                      <StyledInput>
                        <Input
                          name={name}
                          id={name}
                          mode="currency"
                          inputMode="numeric"
                          pattern={/[0-9]*/}
                          type="text"
                          value={actualValue}
                          onChange={(e) =>
                            onChangeActualValue(name, parseFloat(e.rawValue))
                          }
                        />
                      </StyledInput>
                    )}
                  </LineItem>
                )
              )}
            </React.Fragment>
          )
        )}
        <br />
        <LineItem header key="iih">
          <b>{t("internalInterest")}</b>
          <b>{t("calculatedInterestPercentageWithoutGrants")}</b>
          <b>{t("calculatedInterestPercentage")}</b>
        </LineItem>
        <LineItem key="iiv">
          <p>{t("calculatedInternalInterest")}</p>
          <p>{calculatedInterestBeforeGrant}</p>
          <p>{calculatedInterestWithGrant}</p>
        </LineItem>
      </StyledList>
    </Container>
  )
}

export default InternalInterestCalculation

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

const LineItem = styled.li`
  p:first-letter {
    text-transform: capitalize;
  }

  border-radius: 5px;
  padding: 10px;
  margin: 4px;
  display: grid;
  background-color: ${(props) =>
    props.header ? `${Colors.Snow}` : `${ProjectColors.Slate95}`};
  grid-template-columns: ${({ isAdditionalProduct }) =>
    isAdditionalProduct ? "1fr 2fr 1fr 1fr 0.1fr" : "3fr 1.5fr 1fr 0.5fr"};

  :last-child {
    p:last-child {
      padding-left: 4px;
    }
  }
`

const Container = styled.div`
  margin: 0;
  padding: 2rem;
`
