import React from "react"
import Layout from "../components/common/Layout"
import ErrorViewDetailed from "./ErrorViewDetailed"
import Collapsible from "../components/common/Collapsible"
import styled from "styled-components"
import { PrimaryProgressButton } from "@flow/buttons"
import { Icons } from "@flow/icons"
import { Colors } from "@flow/style"

const ErrorView = ({ task, flow, t, schema, save, complete, user, retry }) => {
  const error = flow?.data?.errors?.[task?.taskId]
  const details = { ...error?.details, stacktrace: task?.errorMessage }
  return (
    <Layout forceHeight>
      <MainContent>
        <FriendlyMessage>
          <strong>{t("Feilmelding")}:</strong>{" "}
          {error?.message ||
            error?.details?.stacktrace ||
            task?.errorMessage ||
            t("Ukjent feil")}
        </FriendlyMessage>
        {error?.details && (
          <Collapsible header={t("Detaljer")}>
            <ErrorViewDetailed error={details} />
          </Collapsible>
        )}
        <div>
          <RetryButtonStyled onClick={() => retry()}>
            <Icons.Retry size="20" color={Colors.Snow} />
            {t("Prøv på nytt")}
          </RetryButtonStyled>
        </div>
      </MainContent>
    </Layout>
  )
}

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 100%;
  padding: 2em;
`
const FriendlyMessage = styled.div``

const RetryButtonStyled = styled(PrimaryProgressButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2em;

  svg {
    padding-right: 0.5em;
  }
`

export default ErrorView
