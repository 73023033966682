import React from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { Colors } from "@flow/style"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import AssessmentComments from "../components/common/AssessmentComments"

export class FollowUpDecision extends React.Component {
  constructor(props) {
    super(props)
    const { task } = this.props
    const { data, defaults } = task

    const taskHasData = data && Object.keys(data).length > 0

    this.state = {
      isProcessing: false,
      formData: taskHasData ? data : defaults,
    }
  }

  handleSave() {
    const { save } = this.props

    this.setState({ isProcessing: true })

    save(
      this.state.formData,
      () => {
        this.setState({ isProcessing: false })
      },
      () => {
        console.error("Could not complete task")
        this.setState({ isProcessing: false })
      }
    )
  }

  handleComplete() {
    const { complete } = this.props

    this.setState({ isProcessing: true })

    complete(
      this.state.formData,
      () => {
        this.setState({ isProcessing: false })
      },
      () => {
        console.error("Could not complete task")
        this.setState({ isProcessing: false })
      }
    )
  }

  render() {
    const { t, schema, flow, task } = this.props
    const { isProcessing } = this.state

    return (
      <Layout forceHeight>
        <Content>
          <TabContainer>
            <AssessmentComments
              comments={flow?.data?.assessmentComments}
              t={t}
              assignee={flow?.assignee?.name}
            />
          </TabContainer>
        </Content>
        <Context flow={flow} context={task.context?.applicationSummary}>
          <StyledForm>
            <ReactForm
              key={this.state.id}
              schema={schema}
              formData={this.state.formData}
              disabled={isProcessing}
              onChange={(values) => this.onFormChange(values)}
              onSubmit={(values) => this.handleComplete(values)}
            >
              <ButtonContainer>
                <PrimaryButton
                  type="submit"
                  isLoading={isProcessing}
                  disabled={isProcessing}
                >
                  {t("complete")}
                </PrimaryButton>
                <SecondaryButtonModified
                  type="button"
                  disabled={isProcessing}
                  onClick={() => this.handleSave()}
                >
                  {" "}
                  {t("save")}
                </SecondaryButtonModified>
              </ButtonContainer>
            </ReactForm>
          </StyledForm>
        </Context>
      </Layout>
    )
  }
}

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

const StyledForm = styled.div`
  #root_rejectionText {
    height: 300px !important;
  }
  #root_approvalStatus {
    background-color: ${Colors.Grey4};
    cursor: not-allowed;
  }
`
const Content = styled.div`
  flex: 1;
  padding: 10px 40px;
  overflow-y: auto;
`
const TabContainer = styled.div`
  margin: 20px 0;
  flex: 1;
`

export default withTranslation()(FollowUpDecision)
