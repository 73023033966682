import React, { useState, useCallback } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import InsightModule from "../components/insight/InsightModule"
import { returnFirstArgWithValue } from "../util/returnValue"
import Tabs from "../components/common/Tabs"
import { getInsightNotesContent } from "../util/insightAgreementUtils"

const AdvicePanel = ({ task, t, flow, save, complete, trigger, schema }) => {
  const { data, defaults, context } = task

  const stateData = {}

  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data?.formData, stateData, defaults)
  )
  const [isProcessing, setProcessing] = useState(false)
  const [activeApp, setActiveApp] = useState(null)
  const onAppChange = useCallback(
    (app) => {
      setActiveApp(app)
    },
    [setActiveApp]
  )
  const [activeTab, setActiveTab] = useState("advicepanel")

  const options = [
    {
      id: "advicepanel",
      title: t("advicePanel"),
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          data={{ isReadOnly: false }}
          onAppChange={onAppChange}
          commonData={context.insightComponentData.commonData}
          options={{
            context: "advicePanel",
          }}
        />
      ),
    },
    {
      id: "statementFromOthers",
      title: t("statementFromOthers"),
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          data={{ isReadOnly: false }}
          onAppChange={onAppChange}
          commonData={context.insightComponentData.commonData}
          options={{
            context: "statementFromOthers",
          }}
        />
      ),
    },
  ]

  const onFormChange = (values) => {
    setFormData(values)
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const getNotesContent = async () => {
    setProcessing(true)
    const noteStatusStatementFromOthers = await getInsightNotesContent(
      flow.flowId,
      "statementFromOthers"
    )
    const noteAdvicePanel = await getInsightNotesContent(
      flow.flowId,
      "adviceGivingPanel"
    )
    return { noteStatusStatementFromOthers, noteAdvicePanel }
  }
  const handleComplete = async (values) => {
    let newData
    let statemenFromOthersHasContent = false
    let advicePanelHasContent = false

    const data = await getNotesContent()

    if (data.noteStatusStatementFromOthers === 200) {
      statemenFromOthersHasContent = true
    }

    if (data.noteAdvicePanel === 200) {
      advicePanelHasContent = true
    }

    newData = {
      statemenFromOthersHasContent,
      advicePanelHasContent,
    }

    complete(
      newData,
      () => setProcessing(false),
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <MainContainer>
        <Tabs options={options} onChange={(e) => setActiveTab(e)} />
      </MainContainer>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}

const MainContainer = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`
export default withTranslation()(AdvicePanel)
