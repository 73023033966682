import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import Notice from "../../common/Notice"
import formatWithLocale from "../../../util/dateFormatter"
import { withTranslation } from "react-i18next"
import React from "react"

const AvailableFramesTable = ({
  availableFrames,
  isInDecision,
  t,
  timestamp,
}) => {
  return (
    <>
      {timestamp && (
        <p>
          {t("last-updated-info")}
          {formatWithLocale(timestamp)}
        </p>
      )}

      {availableFrames?.length <= 0 ? (
        <Notice>{t("no-frames-info")}</Notice>
      ) : (
        <>
          <StyledTable>
            <thead>
              <tr>
                <TextAlignLeft>{t("vmCode")}</TextAlignLeft>
                <TextAlignLeft>{t("name")}</TextAlignLeft>
                {isInDecision && (
                  <TextAlignLeft>{t("hasMissingFrame")}</TextAlignLeft>
                )}
                <TextAlignLeft>{t("frameType")}</TextAlignLeft>
                <th>{t("frameOwner")}</th>
                {isInDecision && <th>{t("grantedAmount")}</th>}
                <th>{t("availableAmount")}</th>
              </tr>
            </thead>
            <tbody>
              {availableFrames?.map((row, i) => (
                <tr key={i} className={row?.hasMissingFrame ? "red-row" : "tr"}>
                  <TextAlignLeftTd>{row?.instrumentCode}</TextAlignLeftTd>
                  <TextAlignLeftTd>{row?.productShortName}</TextAlignLeftTd>
                  {isInDecision && (
                    <TextAlignLeftTd>
                      {row?.hasMissingFrame ? t("Yes") : t("No")}
                    </TextAlignLeftTd>
                  )}
                  <TextAlignLeftTd>{t(row?.frameType)}</TextAlignLeftTd>
                  <td>{row?.frameOwner}</td>
                  {isInDecision && (
                    <td>
                      {row?.frameType === "LossFund"
                        ? row?.lossFundAmount
                        : row?.grantedAmount}
                    </td>
                  )}
                  <td>
                    {addThousandSeparator(Math.floor(row?.availableAmount))}
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )}
    </>
  )
}
export default withTranslation("EvaluateBasisForSupport")(AvailableFramesTable)

const TextAlignLeft = styled.th`
  padding: 12px;
  background-color: ${Colors.Grey3};
  text-align: left;
`
const TextAlignLeftTd = styled.td`
  padding: 12px;
  font-size: ${FontStyles.Normal};
  text-align: left;
`
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead,
  tbody {
    text-align: right;
  }

  th {
    padding: 12px;
    background-color: ${Colors.Grey3};
  }

  td {
    padding: 12px;
    font-size: ${FontStyles.Normal};
  }

  tr.red-row {
    background-color: ${Colors.FerrariLighter};
  }

  tr {
    background-color: ${Colors.GrassLighter};
  }
`
