import React, { useCallback, useState } from "react"
import { Tabs as FlowTabs } from "@flow/components"
import styled from "styled-components"
import Spinner from "./Spinner"
import { useTranslation } from "react-i18next"
import { PrimaryButton } from "@flow/buttons"

/**
 * @param {object} props
 * @param {string} [props.className]
 * @param {boolean} props.loading
 * @param {boolean} props.showReloadButton
 * @param {JSX.Element | JSX.Element[]} [props.children]
 * @param {string} props.defaultTab
 * @param {Array} props.options
 * @param {function} props.onChange
 * @param {boolean} [props.disabled]
 */
function Tabs({
  className,
  loading,
  showReloadButton,
  children,
  defaultTab,
  options,
  onChange,
  disabled,
}) {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(defaultTab || options?.at(0)?.id)
  const onTabChange = useCallback(
    (tab) => {
      if (typeof onChange === "function") {
        onChange(tab)
      }
      setActiveTab(tab)
    },
    [onChange, setActiveTab]
  )
  const tab = options.find((x) => x.id === activeTab)
  return (
    <Container className={className}>
      <TabWrapper className="header">
        <FlowTabs
          defaultOptionId={defaultTab}
          options={options}
          onChange={(tab) => onTabChange(tab)}
        />
      </TabWrapper>

      <BackdropBlur hidden={!loading && !showReloadButton}>
        {loading ? (
          <Spinner>{t("wait-while-loading")}</Spinner>
        ) : showReloadButton ? (
          <PrimaryButton onClick={() => window.location.reload()}>
            {t("reload-page")}
          </PrimaryButton>
        ) : (
          <></>
        )}
      </BackdropBlur>

      {children ||
        (tab && (
          <TabContainer className="content" id={tab.id} key={tab.id}>
            {tab.component}
          </TabContainer>
        ))}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: calc(100% - 59px);
  .content {
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
  }
`

const visibleCss = `
  opacity: 100%;
  visibility: visible;
`

const hiddenCss = `
  opacity: 0%;
  visibility: hidden;
`

const BackdropBlur = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 10px);
  height: calc(100% + 80px);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: black;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000;
  margin-left: -10px;
  margin-top: -10px;
  backdrop-filter: blur(2px);
  transition: 200ms;
  ${(props) => (props.hidden ? hiddenCss : visibleCss)};
`

const TabContainer = styled.div`
  flex: 1;
  border-top: 1px solid #e2e2e1;
`

const TabWrapper = styled.div`
  padding: 10px 40px;
  padding-bottom: 0;
`

export default Tabs
