import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import React, { useState } from "react"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import DisplaySecurityText from "../components/TermsAndSecurity/DisplaySecurityText"
import SpecialTermsTab from "../components/AnalyzeApplication/SpecialTermsTab"
import { Tabs } from "@flow/components"
import DepotControl from "../components/DepotControl/DepotControl"
const DepotControlCollateralMovablesManual = ({
  task,
  t,
  flow,
  schema,
  save,
  complete,
}) => {
  const { data, defaults, variables } = task
  const [taskData, setTaskData] = useState(task.data || task.default)
  const [activeTab, setActiveTab] = useState("depotControl")
  const [isProcessing, setProcessing] = useState(false)
  const currentAgreementId = variables?.collateralElement
  const currentAgreement = task.context?.agreementData?.find(
    (x) => x.agreementId === currentAgreementId
  )
  const handleSave = (values) => {
    setProcessing(true)

    save(
      taskData,
      () => {},
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)
    complete(
      taskData,
      () => {},
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const relevantDocuments = flow.data.files.filter(
    (x) =>
      x.documentType === "Tilbudsbrev" &&
      x.documentGroup === "Archive" &&
      x?.metadata?.deliveryId === variables.delivery.id
  )

  const decisionPdf = flow.data.files.filter(
    (x) => x.documentType === "Innstilling" && x.documentGroup === "Søknad"
  )
  const insurancePdf = flow.data.files.filter(
    (x) =>
      x.filename === "Forsikringsavtale for løsøre.pdf" &&
      x.documentGroup === "Archive"
  )

  const options = [
    {
      id: "depotControl",
      title: t("depotControl"),
      component: (
        <MainContainer>
          <DepotControl
            t={t}
            currentAgreement={currentAgreement}
            relevantDocuments={relevantDocuments}
            decisionPdf={decisionPdf}
            insurancePdf={insurancePdf}
          />
        </MainContainer>
      ),
    },
    {
      id: "securityTexts",
      title: "Sikkerhetstekster",
      component: (
        <>
          {flow?.data?.deliveries[variables?.delivery?.id]?.securityTexts?.map(
            (securityText, securityTextIndex) => {
              return (
                <DisplaySecurityText
                  t={t}
                  key={securityTextIndex}
                  isEmpty={!securityText?.subtitle?.length}
                  isFirst={securityTextIndex === 0}
                  securityText={securityText}
                  userGenerated={securityText?.userGenerated}
                  isReadOnly={true}
                />
              )
            }
          )}
        </>
      ),
    },
    {
      id: "specialconditions",
      title: "Særvilkår",
      component: (
        <SpecialTermsTab
          deliveries={flow?.data?.deliveries}
          onChange={() => {}}
          t={t}
          readOnly={true}
        />
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContent>
        <Tabs options={options} onChange={(e) => setActiveTab(e)} />
        <TabContainer>
          {options?.map((x, i) => (
            <div
              key={i}
              style={{ display: x?.id === activeTab ? "block" : "none" }}
            >
              {x?.component}
            </div>
          ))}
        </TabContainer>
      </MainContent>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={schema}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => setTaskData(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave(taskData)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  width: 100%;
  overflow-y: auto;
  gap: 1rem;
`

const BoldTd = styled.td`
  ${"" /*   font-weight: bold; */}
`

const Row = styled.div`
  display: flex;
  gap: 15px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

const Content = styled.div`
  display: flex;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`

const ContentTr = styled.tr`
  display: flex;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`
const TabContainer = styled.div`
  margin: 20px 20px 20px 0;
  flex: 1;
`
export default withTranslation()(DepotControlCollateralMovablesManual)
